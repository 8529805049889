import {
  API_GET_DATA_SURVEY,
  API_GET_IS_SURVEY,
  API_POST_SURVEY,
} from "../../../constants/api.constant";
import { DATA_SURVEY } from "../../../constants/survey-answer";
import {
  AnswerSurveyI,
  IsSurveyRes,
  ResultSurveyI,
} from "../../../interfaces/app.interface";
import { execute } from "../../../libs/request";

export async function getDataSurvey() {
  const res = (await execute.get<IsSurveyRes>(API_GET_IS_SURVEY)).data;
  if (
    res.responseInfo?.error &&
    res.responseInfo.error[0].message === "ERROR"
  ) {
    throw new Error("Error");
  }
  return res.data;
  // return false;
}

export async function getSurveyService() {
  const res = (await execute.get<ResultSurveyI>(API_GET_DATA_SURVEY)).data;
  if (
    res.responseInfo?.error &&
    res.responseInfo.error[0].message === "ERROR"
  ) {
    throw new Error("Error");
  }
  // return DATA_SURVEY.data;
  return res.data;
}

export async function postSurveyService(data: AnswerSurveyI) {
  const res = await execute.post(API_POST_SURVEY, data);
  if (
    res.data.responseInfo?.error &&
    res.data.responseInfo.error[0].message === "ERROR"
  ) {
    throw new Error("Error");
  }
}

export async function checkIsShared() {
  // const data = (await execute.get<IsShareRes>(API_GET_IS_SHARE)).data;
  return true;
  // return false;
}
