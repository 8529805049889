import {
  Box,
  Flex,
  Text,
  useRadio,
  UseRadioProps,
  keyframes,
} from "@chakra-ui/react";

interface RadioCardProps {
  radioProps: UseRadioProps;
  children: string;
  disabled?: boolean;
  isCorrect: boolean;
  correctAnswer: boolean;
}

const bgColorCorrectAnswer =
  "linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)";

const boxShadowCorrentAnswer =
  "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -3px 2px 4px #FFFFBF, inset 2px -2px 4px rgba(0, 0, 0, 0.34)";

const boxShadowNotAnswer =
  "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #F9F7F7, inset 2px -2px 4px rgba(0, 0, 0, 0.34)";

const boxShadowUnCheck =
  "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -3px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34)";

const boxShadowWrongAnswer =
  "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -3px 2px 4px #FFF3F2, inset 2px -2px 4px rgba(0, 0, 0, 0.34)";

const boxShadowActiveAnswer =
  "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #98BD62, inset 2px -2px 4px rgba(0, 0, 0, 0.34)";

const vibrate = keyframes`
0% {left:5px;}
25% {left:-5px;}
50% {left:5px;}
75% {left:-5px;}
100%{left:0px;}
`;

const BG_COLOR = keyframes`
0% {background:#4F8600;box-shadow:-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #98BD62, inset 2px -2px 4px rgba(0, 0, 0, 0.34);}
20% {background:#86C033;box-shadow: 0px 0px 12px #96FF00, inset -2px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34); }
40% {background:#4F8600;box-shadow:-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #98BD62, inset 2px -2px 4px rgba(0, 0, 0, 0.34);}
60% {background:#86C033;box-shadow: 0px 0px 12px #96FF00, inset -2px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34); }
80% {background:#4F8600;box-shadow:-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #98BD62, inset 2px -2px 4px rgba(0, 0, 0, 0.34);}
100% {background:#86C033;box-shadow: 0px 0px 12px #96FF00, inset -2px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34); }
`;

export default function QuestionAnswer({
  radioProps,
  children,
  disabled,
  isCorrect,
  correctAnswer,
}: RadioCardProps) {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const statusBackgroundRadio =
    disabled && correctAnswer
      ? bgColorCorrectAnswer
      : disabled
      ? "#BCBCBC"
      : "#86C033";

  const statusBoxShadowRadio =
    correctAnswer && disabled
      ? boxShadowCorrentAnswer
      : disabled
      ? boxShadowNotAnswer
      : boxShadowUnCheck;

  const statusColorTextRadio = correctAnswer && disabled ? "#004E29" : "#fff";
  return (
    <Box as="label" minHeight="82px" position="relative">
      <input {...input} disabled={disabled} />
      <Flex
        borderRadius="12px"
        p="8px"
        h="full"
        w="full"
        align="center"
        textAlign="center"
        justify="center"
        {...checkbox}
        bg={statusBackgroundRadio}
        boxShadow={statusBoxShadowRadio}
        color={statusColorTextRadio}
        _checked={{
          animation: !isCorrect
            ? `${vibrate} 0.5s linear`
            : `${BG_COLOR} 1s linear`,
          bg: isCorrect ? bgColorCorrectAnswer : "#FFE3E0",
          color: isCorrect ? "#004E29" : "#FF5D4F",
          boxShadow: isCorrect ? boxShadowCorrentAnswer : boxShadowWrongAnswer,
        }}
        _active={{
          bg: "#4F8600",
          boxShadow: boxShadowActiveAnswer,
        }}
        position="absolute"
        transitionProperty="background"
        transitionDelay="0.5s"
        transitionDuration="0.5s"
      >
        <Text fontSize="16px" fontWeight="500" lineHeight="19.5px">
          {children}
        </Text>
      </Flex>
    </Box>
  );
}
