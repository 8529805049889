import { API_GET_HISTORIES } from "../../../constants/api.constant";
import { execute } from "../../../libs/request/index";
import { IHistory, IHistoryResponse } from "./interfaces";

export async function getHistoriesService() {
  try {
    return (
      (await execute.get<IHistoryResponse>(API_GET_HISTORIES)).data.data || []
    );
  } catch (error) {
    console.error(error);
    return [] as IHistory[];
  }
}
