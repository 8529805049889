import { Box, Flex, Image, keyframes } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  updateCurrentScreen,
  updateIsOpenDrawer,
  setCurrentTopic,
  updateTurn,
  updateAwardUrl,
} from "../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import PopUp from "../common/pop_up";
import { DATA_POPUP_ERROR } from "../common/pop_up/constants";
import { postUserAnswer } from "../question/services";
import { Histories } from "./history";
import { getTurnLeft } from "./service";

type Props = {
  handlePlayPauseAudio: () => void;
};

// const tokenConstants =
//   "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMzE5ODMiLCJVc2VyVHlwZSI6IlVTRVIiLCJpYXQiOjE2NDc0NDE1MzUsImV4cCI6MjM2NzQ0MTUzNX0.UALt6aRZo4ujqNoVQsJzu0hoQlY40jjpIGxNvj4jdnLlYHMzbwKsEzgjVHJEgg0st0U45lNNFn62pmoU4Vmciw";

const Home = ({ handlePlayPauseAudio }: Props) => {
  const { innerWidth } = window;
  const turn = useAppSelector((state) => state.appReducer.turn);
  const playAudio = useAppSelector((state) => state.appReducer.playAudio);
  const urlParams = new URLSearchParams(window.location.search);
  const [togglePopUp, setTogglePopUp] = useState(false);
  const token = urlParams.get("token") || "";

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  const dispatch = useAppDispatch();

  const handleOpenModel = () => {
    dispatch(updateIsOpenDrawer(true));
  };

  function handleSwitchDashboard() {
    dispatch(updateCurrentScreen("dashboard"));
  }
  useEffect(() => {
    let cancel = false;
    async function getTurn() {
      try {
        const turnLeft = await getTurnLeft();
        if (!cancel) {
          dispatch(updateTurn(turnLeft));
        }
      } catch (e) {
        console.log(e);
        setTogglePopUp(true);
      }
    }
    getTurn();

    return () => {
      cancel = true;
    };
  }, [dispatch]);

  // useEffect(() => {
  //   const data = localStorage.getItem("userAnswers");
  //   if (data) {
  //     const userAnswers = JSON.parse(data);
  //     (async () => {
  //       try {
  //         await postUserAnswer(userAnswers, (img) => {
  //           dispatch(updateAwardUrl(img));
  //         });
  //         localStorage.removeItem("userAnswers");
  //       } catch (e) {
  //         setTogglePopUp(true);
  //         console.log(e);
  //       }
  //     })();
  //   }
  // }, []);

  useEffect(() => {
    dispatch(
      setCurrentTopic({
        groupId: null,
        groupName: "",
        status: "IN_PROCESS",
        questions: [],
      })
    );
  }, [dispatch]);

  const handlePlayGame = () => {
    if (!playAudio) {
      handlePlayPauseAudio();
    }
    handleSwitchDashboard();
  };

  const handleSwitchRanking = () => {
    dispatch(updateCurrentScreen("rank"));
  };
  function handleClosePopUp() {
    setTogglePopUp(false);
  }
  const glowing = keyframes`
    100% { background-position: 0 0; }
    0% { background-position: 160% 0; }
  `;

  return (
    <Box
      position="absolute"
      w="100vw"
      h={"100vh"}
      minH="800px"
      zIndex={1}
      top={0}
      left={0}
      overflow="hidden"
      bg="#F3F8E1"
    >
      <Flex zIndex={2} h="30%" position="relative" marginTop="110px">
        <Box
          position="absolute"
          w={{
            base: innerWidth * 1.5,
            lg: innerWidth * 1.7,
          }}
          h={{
            base: "100%",
            md: "90%",
          }}
          bg="#F3F8E1"
          borderRadius="50%"
          bottom="0"
          left="50%"
          transform="translateX(-50%)"
          boxShadow="0 4px 4px rgba(0, 0, 0, 0.1)"
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          bgImg="/assets/images/Person_4_1.png"
          bgSize="contain"
          bgRepeat={"no-repeat"}
          bgPosition={"28%"}
        >
          <Box position="relative" w={"50vw"} h="100%">
            <Image
              src={"/assets/images/Gift_1.png"}
              position="absolute"
              top={"12%"}
              left={"50%"}
              w="30%"
              objectFit="cover"
            />
            <Image
              src={"/assets/images/Mum_400g.png"}
              position="absolute"
              bottom={"18%"}
              left={"90%"}
              w="20%"
              objectFit="cover"
            />
            <Image
              src={"/assets/images/Voucher_1.png"}
              position="absolute"
              w="30%"
              objectFit="cover"
              bottom={"2%"}
              left={"70%"}
            />
          </Box>
          <Box position="relative" right={"13px"} top={"-50%"} w="40vw">
            <Image src={"/assets/images/Logo_CLM.png"} w={"100%"} />
            <Image
              src={"/assets/images/Do_Vui_1.png"}
              marginTop="7px"
              w={"100%"}
            />
          </Box>
        </Box>
      </Flex>
      <Flex
        position="absolute"
        zIndex={1}
        bottom={"0"}
        h={"70%"}
        w={"100%"}
        bg="conic-gradient(from 0deg at 100% 100%, #87C033 -0.81deg, #3B9453 244.7deg, #A0C31E 284.31deg, #87C033 359.19deg, #3B9453 604.7deg)"
      >
        {/* <Box
          position="absolute"
          w="200px"
          h="300px"
          bg="conic-gradient(from 107.4deg at -26% 100%, #64AB3D -19.66deg, #A6CF06 250.7deg, #64AB3D 340.34deg, #A6CF06 610.7deg)"
          top="10%"
          filter="blur(65px)"
          zIndex={0}
          left="-20%"
        ></Box>
        <Box
          position="absolute"
          w="200px"
          h="300px"
          bg="conic-gradient(from 107.4deg at -26% 100%, #64AB3D -19.66deg, #A6CF06 250.7deg, #64AB3D 340.34deg, #A6CF06 610.7deg)"
          top="4%"
          filter="blur(65px)"
          zIndex={0}
          right="0%"
        ></Box>
        <Box
          position="absolute"
          w="320px"
          h="200px"
          bg="conic-gradient(from 107.4deg at -26% 100%, #64AB3D -19.66deg, #A6CF06 250.7deg, #64AB3D 340.34deg, #A6CF06 610.7deg)"
          bottom="4%"
          filter="blur(65px)"
          zIndex={0}
          right="0%"
        ></Box> */}
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Box
            w="256px"
            h="64px"
            marginBottom="16px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            borderRadius="45px"
            boxShadow="inset 2px -2px 4px rgba(0, 0, 0, 0.34), inset -4px 4px 4px #FEF693"
            bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%), linear-gradient(90deg, #EADE00 0%, #B79003 36.25%, #C1A200 70.62%, #F9F800 98.75%)"
            onClick={handlePlayGame}
            position="relative"
            _before={{
              content: "''",
              bg: "linear-gradient(-45deg, rgba(255,255,255,0) 22%, rgba(255,254,0,0.6209077380952381) 27%, rgba(255,253,0,0.6153054971988796) 30%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 60%, rgba(255,254,0,0.6181066176470589) 65%, rgba(255,254,0,0.6209077380952381) 68%, rgba(255,255,255,0) 73%)",
              position: "absolute",
              bgRepeat: "no-repeat",
              top: "0px",
              left: "0px",
              bgSize: "400%",
              zIndex: 2,
              filter: "blur(5px)",
              width: "100%",
              height: "100%",
              animation: `${glowing} 2s linear infinite`,
              opacity: 1,
              transition: "opacity 0.5s ease-in-out",
              borderRadius: "45px",
            }}
          >
            <Box
              fontSize="20px"
              fontWeight="700"
              color="#004E29"
              textTransform="uppercase"
            >
              Chơi game
            </Box>
            <Box fontSize="12px" fontWeight="600" color="#004E29">
              Bạn đang có {turn || 0} lượt chơi
            </Box>
          </Box>
          <Box
            w="256px"
            h="64px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="45px"
            boxShadow="inset 2px -2px 4px rgba(0, 0, 0, 0.34), inset -4px 4px 4px #FEF693"
            bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%), linear-gradient(90deg, #EADE00 0%, #B79003 36.25%, #C1A200 70.62%, #F9F800 98.75%)"
            onClick={handleOpenModel}
            mb="16px"
          >
            <Box
              fontSize="20px"
              fontWeight="700"
              color="#004E29"
              textTransform="uppercase"
            >
              Tìm thêm lượt
            </Box>
          </Box>
          {/* <Box
            w="256px"
            h="64px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="45px"
            boxShadow="inset 2px -2px 4px rgba(0, 0, 0, 0.34), inset -4px 4px 4px #FEF693"
            bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%), linear-gradient(90deg, #EADE00 0%, #B79003 36.25%, #C1A200 70.62%, #F9F800 98.75%)"
            onClick={handleSwitchRanking}
          >
            <Box
              fontSize="20px"
              fontWeight="700"
              color="#004E29"
              textTransform="uppercase"
            >
              Bảng xếp hạng
            </Box>
          </Box> */}
        </Box>
        <Histories />
      </Flex>
      <PopUp
        {...DATA_POPUP_ERROR}
        isOpen={togglePopUp}
        onClose={handleClosePopUp}
      />
    </Box>
  );
};

export default Home;
