import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { updateCurrentScreen } from "../../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import PopUp from "../pop_up";
import {
  DATA_POPUP_EXIT,
  DATA_POPUP_REDIRECT_POLICY,
} from "../pop_up/constants";
import { PREFIX_MOBILE_URL } from "./contants";

type NavProps = {
  redirectScreen?: "home" | "question" | "success" | "dashboard";
  backgroundColor?: string;
  handlePlayPauseAudio?: () => void;
};

const Nav = ({ handlePlayPauseAudio }: NavProps) => {
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [redirectPolicy, setRedirectPolicy] = useState(false);

  const playAudio = useAppSelector((state) => state.appReducer.playAudio);
  const currentScreen = useAppSelector(
    (state) => state.appReducer.currentScreen
  );
  const turn = useAppSelector((state) => state.appReducer.turn);

  const topic = useAppSelector((state) => state.appReducer.currentTopic);

  const dispatch = useAppDispatch();
  const handleRedirect = () => {
    switch (currentScreen) {
      case "home":
        window.location.replace(PREFIX_MOBILE_URL + "/mini-game");
        break;
      case "dashboard":
        dispatch(updateCurrentScreen("home"));
        break;
      case "question":
        if (topic.status === "DONE") {
          dispatch(updateCurrentScreen("dashboard"));
        } else {
          setTogglePopUp(true);
        }
        break;
      case "success":
        dispatch(updateCurrentScreen("home"));
        break;
      case "policy":
        dispatch(updateCurrentScreen("home"));
        break;
      case "rank":
        dispatch(updateCurrentScreen("home"));
        break;
      case "share-friends":
        dispatch(updateCurrentScreen("home"));
        break;
      default:
        break;
    }
  };

  const handleRedirectPolicy = () => {
    if (currentScreen === "question" && topic.status !== "DONE") {
      setRedirectPolicy(true);
      setTogglePopUp(true);
    } else {
      dispatch(updateCurrentScreen("policy"));
    }
  };
  function handleClosePopUp() {
    setTogglePopUp(false);
    setRedirectPolicy(false);
  }

  const IconArrow = ["home"].includes(currentScreen)
    ? "/assets/images/Back.svg"
    : ["question", "policy", "rank", "share-friends"].includes(currentScreen)
    ? "/assets/images/Back_White.svg"
    : "/assets/images/Back_Green.svg";

  const IconVolume =
    currentScreen === "question"
      ? "/assets/images/Volume_White.svg"
      : // : currentScreen === "success"
        // ? "/assets/images/Volume_Black.svg"
        "/assets/images/Volume.svg";

  const IconQuestion =
    currentScreen === "question"
      ? "/assets/images/Question_White.svg"
      : // : currentScreen === "success"
      // ? "/assets/images/Question_Black.svg"
      currentScreen === "policy"
      ? ""
      : "/assets/images/Question.svg";

  const titleNav = () => {
    switch (currentScreen) {
      case "policy":
        return "Điều khoản";
      case "rank":
        return "Bảng xếp hạng";
      case "share-friends":
        return "Chia sẻ với bạn bè";
      default:
        return `Lượt chơi: ${turn}`;
    }
  };

  const bg = () => {
    switch (currentScreen) {
      case "home":
        return "#F3F8E1";
      case "policy":
        return "#7FAA12";
      case "share-friends":
        return "#7FAA12";

      default:
        return "transparent";
    }
  };
  return (
    <Flex
      justify="space-between"
      align="center"
      position="fixed"
      // bgColor={currentScreen === "policy" ? "#7FAA12" : "transparent"}
      bg={bg()}
      p={5}
      zIndex={2}
      paddingTop="66px"
      w="100vw"
    >
      <Box onClick={handleRedirect}>
        <Image src={IconArrow} />
      </Box>
      <Box position="absolute" left="50%" transform="translateX(-50%)">
        {currentScreen === "home" ? (
          <Image
            src={"/assets/images/LogoVita.png"}
            h="30px"
            objectFit="contain"
          />
        ) : (
          <Box
            // fontSize={currentScreen === "policy" ? "18px" : "14px"}
            fontSize={
              ["policy", "rank", "share-friends"].includes(currentScreen)
                ? "18px"
                : "14px"
            }
            // fontWeight={currentScreen === "policy" ? "700" : "600"}
            fontWeight={
              ["policy", "rank", "share-friends"].includes(currentScreen)
                ? "700"
                : "600"
            }
            color={
              ["dashboard"].includes(currentScreen)
                ? "black"
                : currentScreen === "success"
                ? "#759B2D"
                : "white"
            }
          >
            {/* {currentScreen === "policy" ? "Điều khoản" : `Lượt chơi: ${turn}`} */}
            {titleNav()}
          </Box>
        )}
      </Box>
      <Flex
        // display={currentScreen === "policy" ? "none" : "flex"}
        display={
          ["policy", "rank", "share-friends"].includes(currentScreen)
            ? "none"
            : "flex"
        }
      >
        <Box position="relative" onClick={handlePlayPauseAudio}>
          <Image src={IconVolume} />
          <Box
            position="absolute"
            bg="white"
            w="2px"
            h="22px"
            // top={currentScreen === "success" ? "0px" : "0px"}
            // left={currentScreen === "success" ? "6px" : "10px"}
            top="0px"
            left="10px"
            backgroundColor={
              currentScreen === "question"
                ? "#759B2D"
                : // : currentScreen === "success"
                  // ? "black"
                  "white"
            }
            transform="rotate(45deg)"
            opacity={playAudio ? 0 : 1}
          ></Box>
        </Box>
        <Box ml={2} onClick={handleRedirectPolicy}>
          <Image src={IconQuestion} />
        </Box>
      </Flex>
      <PopUp
        {...(redirectPolicy ? DATA_POPUP_REDIRECT_POLICY : DATA_POPUP_EXIT)}
        isOpen={togglePopUp}
        onClose={handleClosePopUp}
      />
    </Flex>
  );
};

export default Nav;
