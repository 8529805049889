import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ButtonModalI } from "../../../interfaces/app.interface";
import {
  updateCurrentScreen,
  updateIsOpenDrawer,
  updateIsOpenSurvey,
  updateTurn,
  updateAwardUrl,
} from "../../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { postUserAnswer } from "../../question/services";
import { PREFIX_MOBILE_URL } from "../nav/contants";

export type PopUpProps = {
  image?: string;
  title: string;
  description?: any;
  button: ButtonModalI;
  buttonsExit?: ButtonModalI;
  isOpen: boolean;
  onClose: () => void;
  onClickContinue?: () => void;
  isShorter?: boolean;
};

const PopUp = ({
  image,
  title,
  description,
  button,
  buttonsExit,
  isOpen,
  onClose,
  onClickContinue,
  isShorter,
}: PopUpProps) => {
  const dispatch = useAppDispatch();
  const currentScreen = useAppSelector(
    (state) => state.appReducer.currentScreen
  );

  const turnLeft = useAppSelector((state) => state.appReducer.turn);

  const handleRedirect = (button: ButtonModalI) => {
    if (button?.isCallApi) {
      const data = localStorage.getItem("userAnswers");
      if (data) {
        const userAnswers = JSON.parse(data);
        (async () => {
          try {
            await postUserAnswer(userAnswers, (img) => {
              dispatch(updateAwardUrl(img));
            });
            localStorage.removeItem("userAnswers");
          } catch (e) {
            console.log(e);
          }
        })();
      }
    }
    if (button.redirectMobile) {
      window.location.replace(PREFIX_MOBILE_URL + "/mini-game");
    }
    dispatch(updateCurrentScreen(button.screen));
    if (button.isOpenDrawer) {
      dispatch(updateIsOpenDrawer(true));
    } else if (currentScreen === button.screen) {
      if (button?.isOldTurn) {
        onClose();
      } else if (button.isSurvey) {
        dispatch(updateIsOpenSurvey(true));
      } else {
        dispatch(updateTurn(turnLeft - 1));
        onClickContinue?.();
      }
    }
    onClose();
  };

  const titlePopup =
    title === "haveTurn" ? `Bạn còn ${turnLeft} lượt chơi` : title;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="272px" pt="24px" pb="20px" borderRadius="15px">
        <Flex justify="center">
          <Box
            w="200px"
            h="200px"
            bg="#E9E9E9"
            // mx="36px"
            // bgImg={image ? image : "/assets/images/Person_Popup_1.png"}
            // bgSize="contain"
            // bgRepeat={"no-repeat"}
            // bgPosition={"center"}
            bgColor="#F3F8E1"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
          >
            <Image src={image} objectFit="contain" width="100%" height="100%" />
          </Box>
        </Flex>
        <Text
          textAlign="center"
          mt="16px"
          fontSize="14px"
          fontWeight="700"
          mb="8px"
        >
          {titlePopup}
        </Text>
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
          // px="13px"
          px={isShorter ? "36px" : "13px"}
        >
          {description}
        </Text>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {button?.title && (
            <Box
              w="184px"
              h="40px"
              borderRadius="20px"
              bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)"
              fontSize="14px"
              fontWeight="700"
              color="#004E29"
              mt="12px"
              boxShadow="-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)"
              textTransform="uppercase"
              onClick={() => handleRedirect(button)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box>{button.title}</Box>
            </Box>
          )}
          {buttonsExit?.title && (
            <Box
              w="184px"
              borderRadius="20px"
              fontSize="14px"
              fontWeight="700"
              mt="10px"
              textTransform="uppercase"
              onClick={() => handleRedirect(buttonsExit)}
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="#004E29"
            >
              <Box>{buttonsExit?.title}</Box>
            </Box>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default PopUp;
