import { Flex, HStack, Image } from "@chakra-ui/react";

function QuestionStatus({
  questionNumber,
  questionNumberCorrect,
  setQuestionNumber,
}: {
  questionNumber: number;
  questionNumberCorrect: number;
  setQuestionNumber: (index: number) => void;
}) {
  const handleQuestionNumber = (index: number) => {
    if (index === 4) return;
    setQuestionNumber(index + 1);
  };
  return (
    <HStack>
      {Array.from({ length: 5 }).map((_, index) => {
        const isActiveQuestion = index + 1 === questionNumber;
        const isLastQuestion = index === 4;
        const isPassiveQuestion = index + 1 <= questionNumberCorrect;

        const srcPassImage = isActiveQuestion
          ? "/assets/images/Correct-Status-White.svg"
          : "/assets/images/Correct-Status.svg";

        const srcGiftImage = isActiveQuestion
          ? "/assets/images/Gift-White.svg"
          : "/assets/images/Gift-Green.svg";
        return (
          <Flex
            key={index}
            bg={isActiveQuestion ? "#7FAA12" : "none"}
            color={isActiveQuestion ? "#fff" : "#7FAA12"}
            h="32px"
            w="32px"
            borderRadius="29px"
            border="1px solid #7FAA12"
            justify="center"
            align="center"
            fontSize="16px"
            fontWeight="700"
            onClick={() => {
              handleQuestionNumber(index);
            }}
          >
            {isPassiveQuestion ? (
              <Image src={srcPassImage} />
            ) : isLastQuestion ? (
              <Image src={srcGiftImage} />
            ) : (
              index + 1
            )}
            {/* {isLastQuestion && <Image src={srcGiftImage} />} */}
          </Flex>
        );
      })}
    </HStack>
  );
}

export default QuestionStatus;
