import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

type Props = {};

const Policy = (props: Props) => {
  return (
    <Box position="relative">
      <Box p={"16px"} position="absolute" top="110px">
        <Box
          fontSize="16px"
          color="#7faa12"
          textAlign="center"
          fontWeight="700"
          marginBottom="12px"
        >
          I. ĐIỀU KHOẢN MINIGAME
        </Box>
        <Box
          fontSize="14px"
          fontWeight="500"
          lineHeight="20px"
          marginBottom="12px"
        >
          <Box>
            Khi KH tích 1 lon ColosBaby gold for Mum 800g hoặc 2 lon 400g sẽ
            được tham gia vào game dạng trả lời câu hỏi, có 3 chủ đề ứng với 3
            bộ câu hỏi, người dùng cần trả lời 4 câu đúng liên tục trong bộ câu
            hỏi đã chọn để nhận 1 phần quà ngẫu nhiên.
          </Box>
          <Box marginBottom="14px">
            Nếu trả lời sai ở câu nào, khách hàng sẽ không được trả lời tiếp và
            cần tích xu để có thêm lượt chơi
          </Box>
          <Box marginBottom="14px" fontWeight="700">
            Tổng số lượng quà Chương Trình:
          </Box>
          <Box marginBottom="14px">
            <UnorderedList>
              <ListItem>
                10 giải gói đi sinh, mỗi giải trị giá 10 triệu đồng
              </ListItem>
              <ListItem>
                1000 giải hộp sữa ColosBaby Gold for Mum 400g, mỗi giải trị giá
                281,400 VNĐ
              </ListItem>
              <ListItem>
                5000 giải phiếu mua hàng E-voucher Got it!, mỗi giải trị giá
                20,000 VNĐ{" "}
              </ListItem>
            </UnorderedList>
          </Box>
          <Box>
            Hình thức vận chuyển quà: Quà được chuyển qua đường bưu điện đến địa
            chỉ của khách hàng trúng giải và chuyển khoản cho khách hàng
          </Box>
        </Box>
        <Box
          fontSize="16px"
          color="#7faa12"
          textAlign="center"
          fontWeight="700"
          marginBottom="12px"
        >
          II. THÔNG TIN NHẬN GIẢI
        </Box>
        <Box fontSize="14px" fontWeight="500" lineHeight="20px">
          <UnorderedList>
            <ListItem fontWeight="700">
              10 giải gói đi sinh, mỗi giải trị giá 10 triệu đồng:
            </ListItem>
          </UnorderedList>
          <Box marginBottom="12px">
            Hệ thống xác nhận thông tin qua Zalo OA bao gồm:
          </Box>
          <Box marginBottom="12px">
            <Text fontWeight="700">- Tên giải thưởng</Text>
            <Text fontWeight="700">- Họ và tên người trúng giải</Text>
            <Text fontWeight="700">
              - Thông tin tài khoản ngân hàng, tên ngân hàng (trùng khớp với tên
              người trúng giải)
            </Text>
            <Text fontWeight="700">
              - Hình ảnh CMND/ CCCD/ Hộ chiếu chụp rõ thông tin 2 mặt trước và
              sau của KH (trùng khớp với tên người trúng giải)
            </Text>
            <Text fontWeight="700">- Địa chỉ người trúng giải</Text>
            <Text fontWeight="700">- Số điện thoại người trúng giải </Text>
          </Box>
          <UnorderedList>
            <ListItem fontWeight="700">
              1000 giải hộp sữa ColosBaby Gold for Mum 400g:
            </ListItem>
          </UnorderedList>
          <Box marginBottom="12px">
            <Text>
              - Khách hàng nhận quà thông qua giỏ quà tặng trên app như flow
              thông thường
            </Text>
          </Box>
          <UnorderedList>
            <ListItem fontWeight="700">
              5000 giải phiếu mua hàng E-voucher Got it!, mỗi giải trị giá
              20,000 VNĐ:
            </ListItem>
          </UnorderedList>
          <Box>
            <Text>
              - Khách hàng nhận quà thông qua giỏ quà tặng trên app như flow
              thông thường
            </Text>
          </Box>
          <UnorderedList>
            <ListItem marginTop="30px">
              <i>Chương trình khuyến mãi này không liên quan đến đơn vị Apple</i>
            </ListItem>
          </UnorderedList>
        </Box>
      </Box>
    </Box>
  );
};

export default Policy;
