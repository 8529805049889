import { API_QUESTION_USER } from "../../constants/api.constant";
import { UserAnswerI } from "../../interfaces/app.interface";
import { execute } from "../../libs/request";

export async function postUserAnswer(
  userAnswer: UserAnswerI,
  callback: (img: string) => void
) {
  const res = await execute.post<{
    responseInfo: any;
    data: { giftImage: string };
  }>(API_QUESTION_USER, userAnswer);
  if (
    res.data.responseInfo?.error &&
    res.data.responseInfo.error[0].message === "ERROR"
  ) {
    throw new Error("Error");
  }
  if (res.data?.data?.giftImage) callback(res.data?.data?.giftImage || "");
  return res.data;
}
