// export const BASE_URL = "https://api-stg.vitadairyvietnam.vn/api/app";

// export const BASE_URL = "https://api.vitadairyvietnam.vn/api/app";
export const BASE_URL =
  process.env.REACT_APP_BASE_URL ||
  "https://api-stg.vitadairyvietnam.vn/api/app/";

//Questions
export const API_QUESTION_USER = BASE_URL + "/quiz/v1";

export const API_GET_TOPICS = BASE_URL + "/quiz/v1";

export const API_GET_TURN_LEFT = BASE_URL + "/game/v1/times-played?code=MUM";

export const API_GET_IS_SURVEY = BASE_URL + "/survey/v1/1/me?code=%08MUM";

export const API_POST_SURVEY = BASE_URL + "/survey/v1/history?code=%08MUM";

export const API_GET_DATA_SURVEY = BASE_URL + "/survey/v1/2";

export const API_GET_HISTORIES = BASE_URL + "/game/v1/recent-players?code=MUM";

export const API_START_QUESTION = BASE_URL + "/game/v1/start?code=MUM";

export const API_GET_RANKINGS = BASE_URL + "/quiz/v1/ranking";
