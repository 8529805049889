import React, { useEffect, useState } from "react";
import "./index.css";
// import { getHistoriesService } from "./service";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getHistoryMessage } from "./constants";
import { Box } from "@chakra-ui/react";
import { getHistoriesService } from "./service";

const settings: Settings = {
  className: "history",
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 3000,
  pauseOnHover: false,
  arrows: false,
};

function Histories() {
  const [indexHighlight, setIndexHighlight] = useState(1);
  const [histories, setHistories] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const correctData = await handleMakeCorrectData();
      setHistories(correctData);
    })();

    const intervalId = setInterval(async () => {
      const latestHistories = await handleMakeCorrectData();
      if (
        latestHistories.length <= 4 &&
        JSON.stringify(latestHistories) !== JSON.stringify(histories)
      ) {
        setHistories(latestHistories);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  function handleGetNextHighLight(nextSlide: number = 0) {
    const isLastItem = nextSlide === histories.length - 1;
    setIndexHighlight(isLastItem ? 0 : ++nextSlide);
  }

  async function handleMakeCorrectData() {
    const historiesData = await getHistoriesService();
    const data = await getHistoryMessage(historiesData);
    if (data.length === 1) {
      return [...data, ...data, ...data, ...data];
    }
    if (data.length === 2) {
      return [...data, ...data];
    }
    if (data.length === 3) {
      return [...data, data[0]];
    }
    return data;
  }

  return (
    <Box className="history-container">
      <Box className="title">Người chơi trúng giải gần đây</Box>
      <Box className="items-container">
        <Box>
          <Slider
            {...settings}
            beforeChange={(_currentSlide, nextSlide) => {
              handleGetNextHighLight(nextSlide);
            }}
          >
            {histories.map((history, index) => {
              const isLatest = indexHighlight === index;
              return (
                <Box key={index} className="item-wrapper">
                  <Box className={isLatest ? "item_highlight" : "item"}>
                    {history}
                  </Box>
                </Box>
              );
            })}
          </Slider>
        </Box>
      </Box>
    </Box>
  );
}

export { Histories };
