import { Box, Flex, Image, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PopUp from "../common/pop_up";
import { DATA_POPUP_ERROR } from "../common/pop_up/constants";
// import { DATA_RANK } from "./constant";
import { UserInfo } from "./interface";
import { getListRank } from "./service";
import { secondsToTime, transferPhoneNumber } from "./utils";

function Rank() {
  const { innerHeight: height } = window;
  const size = Math.floor((height - 110) / 64) + 2;
  const [page, setPage] = useState(0);
  const [listRank, setListRank] = useState<UserInfo[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [togglePopUp, setTogglePopUp] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getListRank(page, size);
      if (data.data && data.data.length > 0) {
        const newListRank = [...data.data];
        setListRank(newListRank);
      }
    }
    try {
      fetchData();
    } catch (e) {
      console.log(e);
      setTogglePopUp(true);
    }
  }, []);

  function handleClosePopUp() {
    setTogglePopUp(false);
  }

  const fetchMoreData = async () => {
    // setTimeout(() => {
    //   setListRank([...listRank, ...DATA_RANK.data]);
    // }, 1500);
    try {
      const data = await getListRank(page + 1, size);
      if (data.data && listRank.length < data.responseInfo.total) {
        const newListRank = [...listRank, ...data.data];
        setListRank(newListRank);
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.log(e);
      setTogglePopUp(true);
    }
  };
  return (
    <Box
      w="100%"
      h="100vh"
      position="relative"
      bg="conic-gradient(from 0deg at 100% 100%, #87C033 -0.81deg, #3B9453 244.7deg, #A0C31E 284.31deg, #87C033 359.19deg, #3B9453 604.7deg)"
    >
      <Box
        position="absolute"
        top={"102px"}
        px="16px"
        width="100%"
        // height={`${height - 102}px`}
        h="calc(100vh - 102px)"
        overflowY="auto"
        overflowX={"hidden"}
      >
        {/* <Box> */}
        {listRank.map((item, index) => {
          if (index < 3)
            return (
              <TopThree
                // key={item.userId}
                key={index}
                userName={item.userName}
                phoneNumber={transferPhoneNumber(item.phoneNumber)}
                topicNumber={item.numOfGroupCompleted}
                time={secondsToTime(item.totalTimeSc)}
                iconRank={`/assets/images/Rank${index + 1}.svg`}
              />
            );
          return <React.Fragment key={index}></React.Fragment>;
        })}
        <Box
          // w="calc(100% - 32px)"
          // left={"16px"}
          // h="calc(100vh - 354px)"
          // h={
          //   listRank.length <= 2
          //     ? `calc(100% - 255px + ${(3 - listRank.length) * 72}px)`
          //     : "calc(100% - 255px)"
          // }
          // overflowY="hidden"
          bg="white"
          borderTopRadius="12px"
          // position="absolute"
          // bottom="0"
        >
          <InfiniteScroll
            dataLength={listRank.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={`calc(100vh - 354px + ${
              listRank.length < 3 ? (3 - listRank.length) * 72 : 0
            }px)`}
            loader={
              <Flex
                w="100%"
                justifyContent="center"
                h="50px"
                alignItems="center"
              >
                <Spinner
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="lg"
                />
              </Flex>
            }
          >
            {listRank.map((item, index) => {
              if (index >= 3) {
                return (
                  <RankItem
                    // key={item.userId}
                    key={index}
                    index={index + 1}
                    userName={item.userName}
                    phoneNumber={transferPhoneNumber(item.phoneNumber)}
                    topicNumber={item.numOfGroupCompleted}
                    time={secondsToTime(item.totalTimeSc)}
                  />
                );
              }
              return <React.Fragment key={index}></React.Fragment>;
            })}
          </InfiniteScroll>
        </Box>
        {/* </Box> */}

        {/* {listRank.length > 3 && ( */}

        {/* )} */}
      </Box>
      <PopUp
        {...DATA_POPUP_ERROR}
        isOpen={togglePopUp}
        onClose={handleClosePopUp}
      />
    </Box>
  );
}

interface TopThreeProps {
  userName: string;
  phoneNumber: string;
  topicNumber: number;
  time: string;
  iconRank: string;
}
function TopThree({
  userName,
  phoneNumber,
  topicNumber,
  time,
  iconRank,
}: TopThreeProps) {
  return (
    <Flex
      w="100%"
      h="72px"
      bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 5.55%, #E7E600 89.72%, #C1A200 116.3%)"
      boxShadow="inset 2px -2px 4px rgba(0, 0, 0, 0.34), inset -4px 4px 4px #FEF693"
      borderRadius="12px"
      mb="12px"
      justifyContent="space-between"
      alignItems="center"
      px="12px"
      color="#004E29"
    >
      <Flex justify="center" alignItems="center">
        <Image
          src={iconRank}
          w="24px"
          objectFit="contain"
          align="rank"
          mr="12px"
        />
        <Box>
          <Box fontSize="18px" fontWeight="600">
            {userName}
          </Box>
          <Box fontSize="14px" fontWeight="500">
            {phoneNumber}
          </Box>
        </Box>
      </Flex>

      <Flex>
        <Flex flexDirection="column" justify="center" alignItems="center">
          <Image
            src="/assets/images/tick-xanh.svg"
            align="tick"
            w="20px"
            objectFit="contain"
          />
          <Box fontSize="14px" fontWeight="500">{`0${topicNumber}/03`}</Box>
        </Flex>
        <Flex
          ml="12px"
          flexDirection="column"
          justify="center"
          alignItems="center"
        >
          <Image
            src="/assets/images/dong-ho-cat-xanh.svg"
            w="20px"
            objectFit="contain"
            alt="dong-ho-cat-xanh"
          />
          <Box fontSize="14px" fontWeight="500">
            {time}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

interface RankItemProps {
  userName: string;
  phoneNumber: string;
  topicNumber: number;
  time: string;
  index: number;
}

function RankItem({
  userName,
  phoneNumber,
  topicNumber,
  time,
  index,
}: RankItemProps) {
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        h="64px"
        px="12px"
        //   borderBottom="1px solid #939393"
        color="#939393"
        bg="white"
        borderTopRadius={index === 4 ? "12px" : "0"}
        borderBottom="0.1px solid #ccc"
      >
        <Flex justify="center" alignItems="center">
          <Box mr="18px" fontSize="16px" fontWeight="500" color="#000000">
            {index}
          </Box>
          <Box>
            <Box fontSize="14px" fontWeight="600" color="#444444">
              {userName}
            </Box>
            <Box fontSize="12px" fontWeight="400">
              {phoneNumber}
            </Box>
          </Box>
        </Flex>
        <Flex>
          <Flex flexDirection="column" justify="center" alignItems="center">
            <Image
              src="/assets/images/tich-vang.svg"
              align="tick"
              w="20px"
              objectFit="contain"
            />
            <Box fontSize="12px" fontWeight="500">{`0${topicNumber}/03`}</Box>
          </Flex>
          <Flex
            ml="12px"
            flexDirection="column"
            justify="center"
            alignItems="center"
          >
            <Image
              src="/assets/images/dong-ho-cat-vang.svg"
              w="20px"
              objectFit="contain"
              alt="dong-ho-cat-vang"
            />
            <Box fontSize="12px" fontWeight="500">
              {time}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default Rank;
