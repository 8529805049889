export const secondsToTime = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substring(11, 19);
};

export const transferPhoneNumber = (phoneNumber: string) => {
  const str1 = phoneNumber.substring(0, phoneNumber.length - 3);
  let str2 = phoneNumber.substring(phoneNumber.length - 3, phoneNumber.length);
  str2 = " " + str2;
  const arrStr = str1.split("");
  arrStr.forEach((item, index) => {
    if (index % 3 === 0 && index !== 0 && index !== arrStr.length - 1) {
      str2 = "x " + str2;
    } else {
      str2 = "x" + str2;
    }
  });
  return str2;
};
