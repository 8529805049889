import { createSlice } from "@reduxjs/toolkit";
import { UserAnswerI } from "../interfaces/app.interface";

export type AppState = {
  turn: number;
  gameId?: number | null;
  currentScreen:
    | "home"
    | "dashboard"
    | "question"
    | "success"
    | "policy"
    | "rank"
    | "share-friends";
  isOpenDrawer: boolean;
  isOpenPopUp: boolean;
  isOpenSurvey: boolean;
  playAudio: boolean;
  currentTopic: UserAnswerI;
  awardUrl: string;
};

const initialState: AppState = {
  turn: 0,
  gameId: null,
  currentScreen: "home",
  isOpenDrawer: false,
  isOpenPopUp: false,
  isOpenSurvey: false,
  playAudio: false,
  currentTopic: {
    groupId: null,
    groupName: "",
    status: "IN_PROCESS",
    questions: [],
  },
  awardUrl: "",
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    updateTurn(state, action) {
      state.turn = action.payload;
    },
    updateCurrentScreen(state, action) {
      state.currentScreen = action.payload;
    },
    updateIsOpenDrawer(state, action) {
      state.isOpenDrawer = action.payload;
    },
    updateIsOpenPopUp(state, action) {
      state.isOpenPopUp = action.payload;
    },
    updateIsOpenSurvey(state, action) {
      state.isOpenSurvey = action.payload;
    },
    setCurrentTopic(state, action) {
      state.currentTopic = action.payload;
    },
    updateCurrentTopic(state, action) {
      state.currentTopic.questions = [
        ...state.currentTopic.questions,
        action.payload,
      ];
    },
    updateStatusCurrentTopic(state, action) {
      state.currentTopic.status = action.payload;
    },
    updatePlayAudio(state, action) {
      state.playAudio = action.payload;
    },
    updateGameIdCurrentTopic(state, action) {
      state.gameId = action.payload;
    },
    updateAwardUrl(state, action) {
      state.awardUrl = action.payload || "";
    },
  },
});

export const {
  reducer,
  actions: {
    updateTurn,
    updateCurrentScreen,
    updateIsOpenDrawer,
    updateIsOpenPopUp,
    setCurrentTopic,
    updateCurrentTopic,
    updateStatusCurrentTopic,
    updatePlayAudio,
    updateIsOpenSurvey,
    updateGameIdCurrentTopic,
    updateAwardUrl,
  },
} = appSlice;
