import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Image,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { QUESTION_DATA } from "../../constants/";
import {
  Answer,
  PopUpI,
  QuestionI,
  SurveyI,
  UserAnswerI,
} from "../../interfaces/app.interface";
import {
  updateAwardUrl,
  updateCurrentScreen,
  updateGameIdCurrentTopic,
  updateIsOpenPopUp,
  updateIsOpenSurvey,
} from "../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PopUp from "../common/pop_up";
import {
  DATA_POPUP,
  DATA_POPUP_ERROR,
  DATA_POPUP_GOODLUCK,
  DATA_POPUP_HAVE_TURN,
  DATA_POPUP_NOT_TURN,
} from "../common/pop_up/constants";
import {
  checkIsShared,
  getDataSurvey,
  getSurveyService,
} from "../common/pop_up/service";
import { ModalSurvey } from "../common/pop_up/survey";
import { getStartGame } from "../dashboard/service";
import QuestionCard from "./QuestionCard";
import QuestionStatus from "./QuestionStatuts";
import { postUserAnswer } from "./services";

export default function Question() {
  const dispatch = useAppDispatch();
  const userAnswers = useAppSelector((state) => state.appReducer.currentTopic);
  const [userAnswersTemp, setUserAnswersTemp] = useState(userAnswers);
  const [blackListQuestionId, setBlackListQuestionId] = useState(
    userAnswersTemp?.questions?.map((question) => question.questionId)
  );
  const [currentQuestion, setCurrentQuestion] = useState<QuestionI>({
    ...QUESTION_DATA[0].normalQuestions[0],
    thumbnail: "",
  });
  const [questionNumber, setQuestionNumber] = useState<Number>(
    userAnswersTemp?.questions?.length + 1 === 5
      ? 4
      : userAnswersTemp?.questions?.length + 1
  );
  const [disabled, setDisabled] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isChoosed, setIsChoosed] = useState(false);
  const [timer, setTimer] = useState(30);
  const isOpenPopUp = useAppSelector((state) => state.appReducer.isOpenPopUp);
  const [contentPopUp, setContentPopUp] =
    useState<PopUpI>(DATA_POPUP_HAVE_TURN);
  const turn: number = useAppSelector((state) => state.appReducer.turn);

  const [isSurvey, setIsSurvey] = useState<boolean>(false);
  const [isShared, setIsShared] = useState(true);
  const [survey, setSurvey] = useState<SurveyI>();

  const [timeUp, setTimeUp] = useState<boolean>(false);
  const [isCallApiReload, setIsCallApiReload] = useState(false);

  const isOpenSurvey = useAppSelector((state) => state.appReducer.isOpenSurvey);
  const gameId = useAppSelector((state) => state.appReducer.gameId);

  //get API survey and shared
  useEffect(() => {
    const fetchSurvey = async () => {
      const survey = await getSurveyService();
      setSurvey(survey);
    };

    const fetchIsSurvey = async () => {
      const dataSurvey = await getDataSurvey();
      if (dataSurvey && dataSurvey?.length > 0) setIsSurvey(false);
      else setIsSurvey(true);
    };

    const fetchIsShared = async () => {
      const isShared = await checkIsShared();
      setIsShared(isShared);
    };
    try {
      fetchIsShared();
      fetchIsSurvey();
      fetchSurvey();
    } catch (err) {
      console.log(err);
      setContentPopUp(DATA_POPUP_ERROR);
      dispatch(updateIsOpenPopUp(true));
    }
  }, []);

  const topicQuestion = QUESTION_DATA.find(
    (topic) => topic.topicId === userAnswersTemp.groupId
  );

  function randomCurrentQuestion(): void {
    const whiteListQuestion = topicQuestion?.normalQuestions.filter(
      (question) => !blackListQuestionId.includes(question?.questionId)
    ) as QuestionI[];
    let randomCurrentQuestion;
    if (questionNumber === 4) {
      randomCurrentQuestion =
        topicQuestion?.hardQuestions[
          Math.floor(Math.random() * topicQuestion?.hardQuestions.length)
        ];
    } else {
      randomCurrentQuestion =
        whiteListQuestion[Math.floor(Math.random() * whiteListQuestion.length)];
    }
    setCurrentQuestion(randomCurrentQuestion as QuestionI);
  }

  //handle reload
  console.log(isCallApiReload);
  // useEffect(() => {
  //   window.addEventListener("beforeunload", (event) => {
  //     event.preventDefault();
  //     if (isCallApiReload) {
  //       localStorage.setItem(
  //         "userAnswers",
  //         JSON.stringify({ gameId, ...userAnswersTemp })
  //       );
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener("beforeunload", (event) => {
  //       event.preventDefault();
  //       if (isCallApiReload) {
  //         localStorage.setItem(
  //           "userAnswers",
  //           JSON.stringify({ gameId, ...userAnswersTemp })
  //         );
  //       }
  //     });
  //   };
  // }, [isCallApiReload, userAnswersTemp, gameId]);

  useEffect(() => {
    const fetchUserAnswer = async () => {
      try {
        console.log({ gameId, ...userAnswersTemp });

        await postUserAnswer({ gameId, ...userAnswersTemp }, (img) => {
          dispatch(updateAwardUrl(img));
        });
        localStorage.removeItem("userAnswers");
      } catch (err) {
        setContentPopUp(DATA_POPUP_ERROR);
        dispatch(updateIsOpenPopUp(true));
      }
    };
    const timeDown = setInterval(() => {
      setTimer((time) => {
        if (time === 1) {
          clearInterval(timeDown);
          setDisabled(true);
          setTimeUp(true);
          fetchUserAnswer();
        }
        return time - 1;
      });
    }, 1000);
    if (disabled) {
      const answerE = window.document.getElementById("answer");
      answerE?.scrollIntoView({ behavior: "smooth" });
      clearInterval(timeDown);
    }
    return () => clearInterval(timeDown);
  }, [questionNumber, disabled]);

  //check pop_up
  useEffect(() => {
    isSurvey
      ? setContentPopUp(DATA_POPUP_GOODLUCK)
      : turn > 0
      ? setContentPopUp(DATA_POPUP_HAVE_TURN)
      : setContentPopUp(DATA_POPUP_NOT_TURN);
  }, [isSurvey, turn]);

  //random question from list questions
  useEffect(() => {
    const questionE = window.document.getElementById("question");
    questionE?.scrollIntoView();
    if (userAnswersTemp.status !== "DONE") {
      randomCurrentQuestion();
    } else {
      const questionResult = userAnswersTemp.questions[+questionNumber - 1];

      let questionTemp;
      if (questionNumber === 4) {
        questionTemp = topicQuestion?.hardQuestions.find(
          (question) => question.questionId === questionResult.questionId
        );
      } else {
        questionTemp = topicQuestion?.normalQuestions.find(
          (question) => question.questionId === questionResult.questionId
        );
      }

      setCurrentQuestion(questionTemp as QuestionI);

      setIsCorrect(true);
      if (questionResult.answerTimeSc === 0) {
        setIsChoosed(false);
      } else {
        setIsChoosed(true);
      }
      setTimer(questionResult.answerTimeSc);
      setDisabled(true);
    }
  }, [questionNumber]);

  //handler choose answers
  async function handleChooseAnswer(value: string) {
    const Answer = value.charAt(0);

    if (Answer === currentQuestion.correctAnswer.answer) {
      setIsCallApiReload(true);
      const finishTime = timer;
      let questionUser = {
        questionId: currentQuestion.questionId,
        questionContent: currentQuestion.description,
        answerId: currentQuestion.correctAnswer.answer.charCodeAt(0) - 64,
        answerContent: currentQuestion.correctAnswer.content,
        userAnswerId: value.charAt(0).charCodeAt(0) - 64,
        userAnswerContent: currentQuestion.answers[Answer as Answer],
        questionTimeSc: 30,
        answerTimeSc: finishTime,
      };
      setUserAnswersTemp((oldState) => ({
        ...oldState,
        questions: [...oldState.questions, questionUser],
      }));

      setBlackListQuestionId([...blackListQuestionId, questionUser.questionId]);

      let payload;
      if (questionNumber === 4) {
        // setIsCallApiReload(false);
        payload = {
          ...userAnswersTemp,
          status: "DONE",
          questions: [...userAnswersTemp.questions, questionUser],
        } as UserAnswerI;
        setUserAnswersTemp(payload);
        localStorage.setItem(
          "userAnswers",
          JSON.stringify({ gameId, ...payload })
        );
        try {
          console.log({ gameId, ...payload });
          await postUserAnswer({ gameId, ...payload }, (img) => {
            dispatch(updateAwardUrl(img));
          });
          localStorage.removeItem("userAnswers");
          setIsCallApiReload(false);
        } catch (err) {
          setContentPopUp(DATA_POPUP_ERROR);
          dispatch(updateIsOpenPopUp(true));
        }
      }
      if (questionNumber === 1) {
        payload = {
          ...userAnswersTemp,
          status: "IN_PROCESS",
          questions: [...userAnswersTemp.questions, questionUser],
        } as UserAnswerI;
        setUserAnswersTemp(payload);
        localStorage.setItem(
          "userAnswers",
          JSON.stringify({ gameId, ...payload })
        );
      }
      if (questionNumber < 4 && questionNumber > 1) {
        localStorage.setItem(
          "userAnswers",
          JSON.stringify({
            gameId,
            ...userAnswersTemp,
            questions: [...userAnswersTemp.questions, questionUser],
          })
        );
      }
      setIsCorrect(true);
    } else {
      try {
        console.log({ gameId, ...userAnswersTemp });

        await postUserAnswer({ gameId, ...userAnswersTemp }, (img) => {
          dispatch(updateAwardUrl(img));
        });
        localStorage.removeItem("userAnswers");
        setIsCallApiReload(false);
      } catch (err) {
        setContentPopUp(DATA_POPUP_ERROR);
        dispatch(updateIsOpenPopUp(true));
      }
    }
    setDisabled(true);
    setIsChoosed(true);
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "question",
    onChange: handleChooseAnswer,
  });
  const group = getRootProps();

  function handleContinue() {
    setIsCorrect(false);
    setIsChoosed(false);
    setTimeUp(false);
    if (!isCorrect) {
      // if (!isSurvey && isShared && turn === 0) {
      if (!isSurvey && turn === 0) {
        setContentPopUp(DATA_POPUP);
      }
      // if (!isSurvey && !isShared && turn === 0) {
      if (!isSurvey && turn === 0) {
        setContentPopUp(DATA_POPUP_NOT_TURN);
      }
      dispatch(updateIsOpenPopUp(true));
    } else {
      setDisabled(false);
      setTimer(30);

      if (questionNumber === 4) {
        dispatch(updateCurrentScreen("success"));
      }
      setQuestionNumber(+questionNumber + 1);
      const scrollTop = window.document.getElementById("question");
      scrollTop?.scrollTo({
        top: 0,
      });
    }
  }

  async function handleContinueQuestion() {
    try {
      const gameIdData = await getStartGame();
      dispatch(updateGameIdCurrentTopic(gameIdData));
      randomCurrentQuestion();
      setDisabled(false);
      setTimer(30);
    } catch (err) {
      console.log(err);
      setContentPopUp(DATA_POPUP_ERROR);
      dispatch(updateIsOpenPopUp(true));
    }
  }

  function handleClosePopUp() {
    dispatch(updateIsOpenPopUp(false));
  }

  function handleCloseSurvey() {
    dispatch(updateIsOpenSurvey(false));
  }

  function handleContentNotHaveTurn() {
    setContentPopUp(DATA_POPUP_NOT_TURN);
  }

  function handleSetQuestionNumber(index: number) {
    if (userAnswersTemp.status === "DONE") {
      setQuestionNumber(index);
    }
  }

  const colorTimer = disabled && !isChoosed ? "#FF5D4F" : "#7DA021";

  const srcImageIsCorrectIcon = isCorrect
    ? "/assets/images/Correct.svg"
    : disabled && !isChoosed
    ? "/assets/images/Clock.svg"
    : "/assets/images/Wrong.svg";

  const contentNextButton =
    (disabled && !isCorrect) || questionNumber === 4 ? (
      <Text>Tiếp tục</Text>
    ) : (
      <Text>
        Câu hỏi <br /> tiếp theo
      </Text>
    );

  return (
    <Flex
      position="relative"
      fontSize="20px"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bg="linear-gradient(180deg, #658B09 -6.65%, rgba(158, 195, 29, 0.65) 23.68%, #96BC19 100%)"
      h="100vh"
    >
      <Box
        w="full"
        h="200px"
        position="absolute"
        top={0}
        bg="linear-gradient(to bottom, #658B09 -6.65%, #a3c04d)"
      >
        <Flex
          position="absolute"
          bgColor="#fff"
          w="64px"
          px="11.5px"
          py="6.5px"
          justify="center"
          align="center"
          bottom="54px"
          left="50%"
          transform=" translate(-50%, 0)"
          borderRadius="30px"
          boxShadow="0px 0px 4px #FEFFF9, inset 2px -2px 8px #FEFFF9, inset 0px 2px 8px #FEFFF9"
        >
          <Text
            fontSize="14px"
            fontWeight="700"
            lineHeight="17px"
            color={colorTimer}
          >
            00:{timer >= 10 ? timer : `0${timer}`}
          </Text>
        </Flex>
      </Box>
      <VStack
        id="question"
        position="absolute"
        w="full"
        px="16px"
        pb="60px"
        bg="#fff"
        top="170px"
        bottom="0"
        overflow="scroll"
        borderRadius=" 24px 24px 0px 0px"
      >
        <Flex justify="center" pb="10px" pt="24px">
          <Image src="/assets/images/LOGO-CLM.png" h="36px" />
        </Flex>
        <Box w="full" id="top">
          <Text fontSize="20px" fontWeight="600">
            {`Câu ${questionNumber}`}
          </Text>
        </Box>
        <Text fontSize="16px" fontWeight="500" py="8px" mt="0px !important">
          {currentQuestion.description}
        </Text>
        <Image src={currentQuestion.thumbnail} w="full" mt="0px !important" />
        <Grid
          w="full"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap="16px"
          {...group}
          borderBottom="1px solid #E9E9E9"
          py="32px"
        >
          {Object.keys(currentQuestion.answers).map((value) => {
            const label = `${value}: ${
              currentQuestion.answers[value as Answer]
            }`;
            const radio = getRadioProps({ value: label });
            if (radio.isChecked) {
              radio.isChecked = isChoosed;
            }
            return (
              <QuestionCard
                key={value}
                radioProps={radio}
                disabled={disabled}
                isCorrect={isCorrect}
                correctAnswer={value === currentQuestion.correctAnswer.answer}
              >
                {label}
              </QuestionCard>
            );
          })}
        </Grid>
        {disabled && (
          <VStack id="answer" w="full" mt="10px !important">
            <Flex align="center" gap="6.5px" w="full" pb="8px">
              <Image src={srcImageIsCorrectIcon} w="24px" h="24px" />
              <Text
                fontSize="14px"
                fontWeight="600"
                lineHeight="19.5px"
                color={isCorrect ? "#2DC76D" : "#FF5D4F"}
              >
                {/* {isCorrect ? "Chính xác luôn!" : "Sai rồi nhé, dừng lại thôi!"} */}
                {timeUp
                  ? "Hết thời gian rồi, dừng lại thôi!"
                  : isCorrect
                  ? "Chính xác luôn!"
                  : "Sai rồi nhé, dừng lại thôi!"}
              </Text>
            </Flex>
            {/* <Image src={currentQuestion.thumbnail} w="full" /> */}
            <VStack fontSize="14px">
              <Text fontWeight="400">
                <Text fontWeight="600" display="inline" pr="3px">
                  {currentQuestion.correctAnswer.description.trim() !== "" &&
                    " Đáp án:"}
                </Text>
                {currentQuestion.correctAnswer.description}
              </Text>
            </VStack>
          </VStack>
        )}
        <Box
          position="fixed"
          bottom={0}
          left={0}
          right={0}
          px="16px"
          h="60px"
          bgColor="white"
        >
          <HStack w="full" h="full">
            <Box w="full">
              <QuestionStatus
                questionNumber={+questionNumber}
                questionNumberCorrect={userAnswersTemp?.questions?.length}
                setQuestionNumber={handleSetQuestionNumber}
              />
            </Box>
            {disabled ? (
              <Button
                variant="solid_1"
                disabled={!disabled}
                onClick={handleContinue}
                w={!isCorrect && isChoosed ? "96px" : ""}
              >
                {contentNextButton}
              </Button>
            ) : (
              ""
            )}
          </HStack>
        </Box>
      </VStack>
      <PopUp
        {...contentPopUp}
        isOpen={isOpenPopUp}
        onClose={handleClosePopUp}
        onClickContinue={handleContinueQuestion}
      />
      {survey && (
        <ModalSurvey
          survey={survey}
          isOpen={isOpenSurvey && isSurvey}
          onClose={handleCloseSurvey}
          onNotHaveTurn={handleContentNotHaveTurn}
        />
      )}
    </Flex>
  );
}
