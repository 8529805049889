const baseStyle = {
  fontWeight: "700",
  colorScheme: "teal",
  borderRadius: "4px",
};

const defaultProps = {
  colorScheme: "none",
};

const variantSolid_1 = () => ({
  bg: "linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)",
  h: "40px",
  px: "40px",
  borderRadius: "36px",
  boxShadow:
    "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
  fontSize: "14px",
  color: "#004E29",
});

const variantSolid_2 = () => ({
  bg: "linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)",
  w: "256px",
  h: "40px",
  py: "11.5px",
  lineHeight: "17px",
  borderRadius: "38px",
  fontSize: "14px",
  fontWeight: "700",
  color: "#004E29",
  boxShadow:
    "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
});
const variants = {
  solid_1: variantSolid_1,
  solid_2: variantSolid_2,
};

const buttonThemes = {
  baseStyle,
  variants,
  defaultProps,
};
export { buttonThemes };
