import {
  API_GET_TOPICS,
  API_START_QUESTION,
} from "../../constants/api.constant";
import { TOPICS_DATA } from "../../constants/user-answer";
import { ResultStartGamrI, UserAnswerI } from "../../interfaces/app.interface";
import { execute } from "../../libs/request";

export async function getTopics(): Promise<UserAnswerI[]> {
  const response = (await execute.get<any>(API_GET_TOPICS)).data;

  if (
    response.responseInfo?.error &&
    response.responseInfo?.error[0]?.message === "ERROR"
  ) {
    throw new Error("Error");
  }
  return response.data;

  // return TOPICS_DATA;
}

export async function getStartGame() {
  const response = (await execute.get<ResultStartGamrI>(API_START_QUESTION))
    .data;
  if (
    response.responseInfo?.error &&
    response.responseInfo?.error[0]?.message === "ERROR"
  ) {
    throw new Error("Error");
  }
  return response.data.gameId;
}
