import { Box, Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import {
  updateCurrentScreen,
  updateIsOpenDrawer,
} from "../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { PREFIX_MOBILE_URL } from "../common/nav/contants";

export default function Success() {
  const { awardUrl } = useAppSelector((state) => state.appReducer);
  const dispatch = useAppDispatch();

  const handleOpenModel = () => {
    dispatch(updateCurrentScreen("home"));
    dispatch(updateIsOpenDrawer(true));
  };

  const handleSwitchMobile = () => {
    window.location.replace(PREFIX_MOBILE_URL + "/my-reward?tab=gift");
  };
  return (
    <VStack w="full" h="100vh" px="16px" pt="128px" spacing="24px" bg="#F3F8E1">
      {/* <Image src="/assets/images/Success.png" w="full" h="24vh" /> */}
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        mb="40px"
      >
        <Image src={awardUrl} w="240px" objectFit="contain" />
        <Image
          src="/assets/images/Shadow.png"
          w="168px"
          objectFit="contain"
          position="absolute"
          top="225px"
        />
      </Flex>

      <Box px="24px" textAlign="center">
        <Text fontSize="14px" fontWeight="500" lineHeight="19.5px">
          <Text>Chúc mừng bạn đã hoàn thành câu hỏi.</Text>{" "}
          <Text>
            Bấm{" "}
            <Text as="span" fontWeight="bold">
              NHẬN QUÀ NGAY
            </Text>{" "}
            hoặc{" "}
            <Text as="span" fontWeight="bold">
              tìm thêm lượt
            </Text>{" "}
            chơi mới.
          </Text>
        </Text>
        <Text
          fontSize="12px"
          fontWeight="500"
          lineHeight="19.5px"
          fontStyle="italic"
          color="red"
          mt="2px"
        >
          *Hàng ngàn phần quà tặng hấp dẫn đang chờ
        </Text>
      </Box>
      <VStack spacing="12px" w="full">
        <Button variant="solid_2" onClick={handleSwitchMobile}>
          XEM QUÀ NGAY
        </Button>
        <Button variant="solid_2" onClick={handleOpenModel}>
          TÌM THÊM LƯỢT
        </Button>
      </VStack>
    </VStack>
  );
}
