import { API_GET_TURN_LEFT } from "../../constants/api.constant";
import { ResultTurnLeft } from "../../interfaces/app.interface";
import { execute } from "../../libs/request";

export async function getTurnLeft() {
  return (
    (await execute.get<ResultTurnLeft>(API_GET_TURN_LEFT)).data.data
      .numberOfTimesPlayed || 0
  );
}
