import { API_GET_RANKINGS } from "../../constants/api.constant";
import { execute } from "../../libs/request";
import { DATA_RANK } from "./constant";
import { ResultRankings } from "./interface";

export async function getListRank(page: number, size: number) {
  const response = (
    await execute.get<ResultRankings>(API_GET_RANKINGS, {
      headers: {
        page,
        size,
      },
    })
  ).data;
  if (
    response.responseInfo?.error &&
    response.responseInfo?.error[0]?.message === "ERROR"
  ) {
    throw new Error("Error");
  }
  return response;
}
