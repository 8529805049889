export const propTopics = [
  {
    bg: "#FFFFEC",
    iconDone: "/assets/images/Done_Yellow.svg",
    borderColor: "#F8D03D",
    color: "#F8D03D",
    bgButton: "#F8D03D",
    boxShadow:
      "-1px 1px 4px rgba(0, 0, 0, 0.25), inset -3px 2px 4px #FEF2CB, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
    image: "/assets/images/Person_4_2.png",
    arrowIcon: "/assets/images/Arrow_Yellow.svg",
    giftBox: "/assets/images/Gift_Box_Yellow.svg",
  },
  {
    bg: "rgba(23, 181, 60, 0.1)",
    iconDone: "/assets/images/Done_Green.svg",
    borderColor: "#87C033",
    color: "#87C033",
    bgButton: "#86C033",
    boxShadow:
      "-1px 1px 4px rgba(0, 0, 0, 0.25), inset -3px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
    image: "/assets/images/Person_4_3.png",
    arrowIcon: "/assets/images/Arrow_Green.svg",
    giftBox: "/assets/images/Gift_Box_Green.svg",
  },
  {
    bg: "rgba(242, 159, 5, 0.1)",
    iconDone: "/assets/images/Done_Orange.svg",
    borderColor: "#F29F05",
    color: "#F29F05",
    bgButton: "#F29F05",
    boxShadow:
      "-1px 1px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FFE49C, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
    image: "/assets/images/Person_4_4.png",
    arrowIcon: "/assets/images/Arrow_Orange.svg",
    giftBox: "/assets/images/Gift_Box_Orange.svg",
  },
];

export const TOPICS_DATA = [
  {
    groupId: 1,
    groupName: "Bộ câu hỏi số 1",
    status: "NOT",
    questions: [],
  },
  {
    groupId: 2,
    groupName: "Bộ câu hỏi số 2",
    status: "NOT",
    questions: [],
  },
  {
    groupId: 3,
    groupName: "Bộ câu hỏi số 3",
    status: "NOT",
    questions: [],
  },
];

export function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max + 1 - min) + min);
}
