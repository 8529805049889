import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { UserAnswerI } from "../../interfaces/app.interface";
import {
  setCurrentTopic,
  updateCurrentScreen,
  updateGameIdCurrentTopic,
  updateTurn,
} from "../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import PopUp from "../common/pop_up";
import { DATA_POPUP, DATA_POPUP_ERROR } from "../common/pop_up/constants";
import { propTopics, randomNumber } from "./constants";
import { getStartGame, getTopics } from "./service";

type Props = {};

const Dashboard = (props: Props) => {
  const [topics, setTopics] = useState<UserAnswerI[]>([]);
  const [togglePopUp, setTogglePopUp] = useState(false);
  const [isError, setIsError] = useState(false);

  const dispatch = useAppDispatch();

  const turn = useAppSelector((state) => state.appReducer.turn);

  async function handleSwitchQuestion(topic: UserAnswerI) {
    console.log(topic);
    try {
      if (topic.status !== "DONE" && turn > 0) {
        const gameId = await getStartGame();
        dispatch(updateGameIdCurrentTopic(gameId));

        dispatch(updateTurn(turn - 1));
      }
      if (topic.status !== "DONE" && turn <= 0) {
        setTogglePopUp(true);
      } else if (topic.status !== "DONE") {
        dispatch(setCurrentTopic(topic));
        dispatch(updateCurrentScreen("question"));
      }
    } catch (e) {
      console.log(e);
      setIsError(true);
      setTogglePopUp(true);
    }
  }

  useEffect(() => {
    let cancel = false;
    async function fetchTopics() {
      try {
        let topics = await getTopics();
        let newTopics = [] as UserAnswerI[];
        if (topics) newTopics = [...topics];
        let validTopics = newTopics.map((topic) => {
          if (!topic?.questions) {
            return { ...topic, questions: [] };
          }
          return topic;
        });

        if (!cancel) {
          const remakeTopics = getValidTopic(validTopics);
          setTopics(remakeTopics);
        }
      } catch (err) {
        console.log(err);
        setIsError(true);
        setTogglePopUp(true);
      }
    }
    fetchTopics();
    return () => {
      cancel = true;
    };
  }, []);

  function getValidTopic(topics: UserAnswerI[]) {
    let newTopics = [...topics];
    while (newTopics.length < 3) {
      const existedTopicId = newTopics.map((it) => it.groupId);
      const groupId = randomNumber(1, 3);
      if (!existedTopicId.includes(groupId)) {
        newTopics = [
          ...newTopics,
          {
            groupId,
            groupName: `Bộ câu hỏi số ${groupId}`,
            status: "IN_PROCESS",
            questions: [],
          },
        ];
      }
    }
    return newTopics.sort((a, b) => {
      return (a.groupId || 0) - (b.groupId || 0);
    });
  }
  function handleClosePopUp() {
    setTogglePopUp(false);
    setIsError(false);
  }

  return (
    <Box position="relative">
      <Flex
        position="absolute"
        bg="#FFF"
        w="100vw"
        h={"calc(100vh - 110px)"}
        top={"110px"}
      >
        <Box mt="10px" overflowY="auto">
          {topics.map((topic, index) => {
            const {
              bg,
              iconDone,
              borderColor,
              color,
              bgButton,
              boxShadow,
              image,
              arrowIcon,
              giftBox,
            } = propTopics[index];
            return (
              <ComponentTopic
                key={topic?.groupId}
                bg={bg}
                iconDone={iconDone}
                borderColor={borderColor}
                color={color}
                bgButton={bgButton}
                boxShadow={boxShadow}
                image={image}
                arrowIcon={arrowIcon}
                giftBox={giftBox}
                handleSwitchQuestion={handleSwitchQuestion}
                topic={topic}
              />
            );
          })}
        </Box>
      </Flex>
      <PopUp
        {...(isError ? DATA_POPUP_ERROR : DATA_POPUP)}
        isOpen={togglePopUp}
        onClose={handleClosePopUp}
      />
    </Box>
  );
};

interface TopicProps {
  handleSwitchQuestion: (topic: UserAnswerI) => void;
  bg: string;
  iconDone: any;
  borderColor: string;
  color: string;
  bgButton: string;
  boxShadow: string;
  image: string;
  arrowIcon: string;
  giftBox: string;
  topic: UserAnswerI;
}
const ComponentTopic = (props: TopicProps) => {
  const {
    handleSwitchQuestion,
    bg,
    iconDone,
    borderColor,
    color,
    bgButton,
    boxShadow,
    image,
    arrowIcon,
    giftBox,
    topic,
  } = props;

  return (
    <Flex
      margin="16px"
      width="calc(100vw - 32px)"
      minHeight="200px"
      maxHeight="400px"
      height="28%"
      borderRadius="16px"
      boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
      justifyContent="space-between"
      bg={bg}
    >
      <Box
        p={"20px 0px 20px 20px"}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex={1}
      >
        <Box>
          <Flex>
            {Array.from({ length: 4 }).map((_, index) => {
              if (
                topic?.questions &&
                topic?.questions[index] &&
                topic?.status === "DONE"
              ) {
                if (index !== 3) {
                  return (
                    <React.Fragment key={index}>
                      <Image src={iconDone} w="28px" h="28px" color="white" />
                      <Image src={arrowIcon} m="0 4px" />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Image
                      src={iconDone}
                      w="28px"
                      h="28px"
                      color="white"
                      key={index}
                    />
                  );
                }
              } else {
                if (index !== 3) {
                  if (topic?.questions && topic?.questions[index]) {
                    return (
                      <React.Fragment key={index}>
                        <Image
                          src={iconDone}
                          minWidth="28px"
                          width="28px"
                          color="white"
                        />
                        <Image src={arrowIcon} m="0 4px" />
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={index}>
                        <Box
                          minWidth="28px"
                          minHeight="28px"
                          border="1px solid"
                          borderRadius="50%"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          fontSize="12px"
                          fontWeight="600"
                          borderColor={borderColor}
                          color={color}
                        >
                          <Box>{index + 1}</Box>
                        </Box>
                        <Image src={arrowIcon} m="0 4px" />
                      </React.Fragment>
                    );
                  }
                } else {
                  return (
                    <Image
                      src={giftBox}
                      w="28px"
                      h="28px"
                      color="white"
                      key={index}
                    />
                  );
                }
              }
            })}
          </Flex>
        </Box>
        <Box
          fontSize="20px"
          fontWeight="700"
          lineHeight="22px"
          marginTop="16px"
          color={color}
        >
          {topic?.groupName}
          <Box fontSize="10px" fontWeight="500" lineHeight="12px" mt="4px">
            Mẹ cần hoàn thành{" "}
            <Text as="span" fontWeight="700">
              4 câu hỏi
            </Text>{" "}
            để nhận quà
          </Box>
        </Box>
        <Box
          height="40px"
          width="133px"
          marginTop="16px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="38px"
          onClick={() => {
            handleSwitchQuestion(topic);
          }}
          bg={bgButton}
          boxShadow={boxShadow}
        >
          <Box
            fontSize="14px"
            fontWeight="700"
            textTransform="uppercase"
            color="#ffffff"
          >
            {topic?.status === "DONE"
              ? "Đã nhận quà"
              : topic?.questions.length > 0
              ? "Tiếp tục"
              : "Chơi ngay"}
          </Box>
        </Box>
      </Box>
      <Box
        bgImg={image}
        w="100%"
        bgSize="contain"
        bgPosition="bottom right"
        bgRepeat={"no-repeat"}
        flex={1}
      ></Box>
    </Flex>
  );
};

export default Dashboard;
