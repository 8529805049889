import {
  Box,
  Flex,
  Text,
  useCheckbox,
  UseCheckboxProps,
} from "@chakra-ui/react";
import React from "react";

interface CheckboxCardProps {
  checkboxProps: UseCheckboxProps;
  children: string;
}

export default function SurveyCard({
  checkboxProps,
  children,
}: CheckboxCardProps) {
  const { getInputProps, getCheckboxProps } = useCheckbox(checkboxProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="100%">
      <input {...input} />
      <Flex
        {...checkbox}
        alignItems="center"
        w="100%"
        h="54px"
        bg="#F3F3F3"
        p="8px"
        borderRadius="6px"
        _checked={{
          bg: "#86C033",
          color: "#fff",
          boxShadow:
            "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #CBED9A, inset 2px -2px 4px rgba(0, 0, 0, 0.34)",
        }}
      >
        <Text fontSize="14px" fontWeight="500" lineHeight="19.5px">
          {children}
        </Text>
      </Flex>
    </Box>
  );
}
