export const DATA_POPUP = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Bạn đã hết lượt chơi",
  isShorter: true,
  description: (
    <p>
      Hiện tại bạn đã <span style={{ fontWeight: "bold" }}>hết lượt chơi</span>,
      tìm lượt mới ngay thôi nào
    </p>
  ),
  button: { title: "TÌM THÊM LƯỢT", isOpenDrawer: true, screen: "home" },
  buttonsExit: { title: "TRANG CHỦ", screen: "home" },
};

export const DATA_POPUP_EXIT = {
  image: "/assets/images/Person_PopUp_Exit.png",
  title: "Bạn chắc chắn muốn thoát?",
  description:
    "Nếu thoát, bạn sẽ mất lượt chơi này, bạn có chắc chắn muốn thoát?",
  button: { title: "Ở LẠI TRANG", screen: "question", isOldTurn: true },
  buttonsExit: { title: "THOÁT", screen: "home", isCallApi: true },
};

export const DATA_POPUP_REDIRECT_POLICY = {
  image: "/assets/images/Person_PopUp_Exit.png",
  title: "Bạn chắc chắn muốn thoát?",
  description:
    "Nếu thoát, bạn sẽ mất lượt chơi này, bạn có chắc chắn muốn thoát?",
  button: { title: "Ở LẠI TRANG", screen: "question", isOldTurn: true },
  buttonsExit: { title: "THOÁT", screen: "policy" },
};

export const DATA_POPUP_NOT_TURN = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Bạn đã hết lượt chơi",
  description: "Hiện tại bạn đã hết lượt chơi, tìm lượt mới ngay thôi nào",
  button: {
    title: "TÌM THÊM LƯỢT",
    screen: "home",
    isOpenDrawer: true,
  },
  buttonsExit: {
    title: "TRANG CHỦ",
    screen: "home",
  },
};

export const DATA_POPUP_HAVE_TURN = {
  image: "/assets/images/Person_PopUp_Have_Turn.png",
  title: "haveTurn",
  description: "Quà tặng hấp dẫn đang chờ bạn phía trước, cố lên nào",
  isShorter: true,
  button: {
    title: "TIẾP TỤC",
    screen: "question",
  },
  buttonsExit: {
    title: "TRANG CHỦ",
    screen: "home",
  },
};

export const DATA_POPUP_GOODLUCK = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Chúc bạn may mắn lần sau!",
  description:
    "Hãy tiếp tục tham gia để có cơ hội may mắn trúng giải! Chia sẻ với VitaDairy bạn biết thông tin chương trình khuyến mãi từ đâu nhé!",
  button: { title: "TRẢ LỜI", screen: "question", isSurvey: true },
};

export const DATA_POPUP_ERROR = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Chương trình đang cập nhật",
  description: "Chương trình đang cập nhật! Vui lòng quay lại sau nhé!",
  button: { title: "QUAY LẠI", redirectMobile: true },
};

export const DATA_POPUP_TICH_XU = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Hãy tích thêm xu để tham gia",
  description: (
    <p>
      Hiện tại bạn không có lượt chơi, hãy{" "}
      <span style={{ fontWeight: "bold" }}>
        đổi muỗng sản phẩm Colosbaby Mum
      </span>{" "}
      để nhận lượt chơi nhé!
    </p>
  ),
  button: {
    title: "TÍCH XU NGAY",
    screen: "question",
  },
  buttonsExit: {
    title: "ĐỂ SAU",
    screen: "home",
  },
};

export const DATA_POPUP_SHARE_SUCCESS = {
  image: "/assets/images/Person_PopUp_TurnOut.png",
  title: "Chia sẻ thành công",
  description: "Chúc mừng bạn đã chia sẻ thành công và nhận thêm lượt chơi mới",
  isShorter: true,
  button: {
    title: "CHƠI NGAY",
    screen: "dashboard",
  },
  buttonsExit: {
    title: "THOÁT",
    screen: "home",
  },
};
