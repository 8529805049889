import axios from "axios";
import { BASE_URL } from "../../constants/api.constant";

const execute = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: BASE_URL,
});

execute.interceptors.request.use(async (config) => {
  // Handle anything before request (as add token)
  const token = localStorage.getItem("token");

  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    ...config,
  };
});

export { execute };
