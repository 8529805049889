import { QuestionTopicI } from "../interfaces/app.interface";

export const QUESTION_DATA: QuestionTopicI[] = [
  {
    topicId: 1,
    normalQuestions: [
      {
        questionId: 1,
        thumbnail: "/assets/images/A2.jpg",
        thumbnailType: "img",
        description:
          "ColosBaby Gold For Mum cung cấp năng lượng hợp lý, giàu đạm, ít béo, giúp cung cấp vừa đủ năng nượng và Protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp…?",
        answers: {
          A: "Tăng cân hợp lý",
          B: "Phát triển hệ xương cho bé",
          C: "Kiểm soát cân nặng",
          D: "Cả A và C đều đúng",
        },
        correctAnswer: {
          answer: "D",
          content: "Cả A và C đều đúng",
          description:
            "ColosBaby for Mum giúp Kiểm Soát Cân nặng cho Mẹ nhờ cung cấp năng lượng hợp lý, giàu đạm, ít béo, tỷ lệ vừa đủ năng lượng và protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp Mẹ tăng cân hợp lý và kiểm soát cân nặng trong thai kỳ và sau sinh. Kiểm soát tốt cân nặng trong thời kỳ mang thai đồng thời giúp bé có số cân nặng ổn định khi chào đời.",
        },
      },
      {
        questionId: 2,
        thumbnail: "/assets/images/A3.jpg",
        thumbnailType: "img",
        description:
          "Đánh giá cảm quan của đại đa số các Mẹ cho hương vị của ColosBaby Gold for Mum so với các loại sữa bầu khác là gì?",
        answers: {
          A: "Vị ngọt đậm",
          B: "Vị nhạt mát",
          C: "Vị thơm ngon dễ uống",
          D: "Vị tanh nhiều",
        },
        correctAnswer: {
          answer: "C",
          content: "Vị thơm ngon dễ uống",
          description: "",
        },
      },
      {
        questionId: 3,
        thumbnail: "/assets/images/A5.jpg",
        thumbnailType: "img",
        description:
          "Trong sữa non ColosIgG 24h có chứa hàm lượng kháng thể IgG cao, vậy vai trò của kháng thể IgG là gì?",
        answers: {
          A: "Giảm bệnh vặt",
          B: "Miễn dịch đường tiêu hoá",
          C: "Bảo vệ đường hô hấp",
          D: "Tất cả đáp án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả đáp án trên",
          description:
            "IgG là loại kháng thể có số lượng nhiều nhất Vầ có khả năng hoạt động miễn dịch mạnh nhất trong cơ thể. IgG có khả năng gắn kết giúp cơ thể tiêu diệt các yếu tố gây bệnh. Bổ sung lượng kháng thể cao IgG từ sữa non ColosIgG24h là cách trực tiếp để cơ thể bé có lượng IgG cao ổn định, luôn sẵn sàng bảo vệ bé khỏi các tác nhân gây bệnh, giúp bé khỏe mạnh và giảm bệnh vặt.",
        },
      },
    ],
    hardQuestions: [
      {
        questionId: 4,
        thumbnail: "/assets/images/A1.jpg",
        thumbnailType: "img",
        description:
          "ColosBaby Gold For Mum bổ sung canxi, Vitamin D3, Photpho, giúp mang lại lợi ích chính gì cho mẹ và bé?",
        answers: {
          A: "Giúp tiêu hóa tốt",
          B: "Giúp phát triển trí não",
          C: "Giúp xương chắc khỏe",
          D: "Giúp tăng cường miễn dịch",
        },
        correctAnswer: {
          answer: "C",
          content: "Giúp xương chắc khỏe",
          description:
            "ColosBaby Gold for Mum giúp hệ xương chắc khỏe vì thành phần có chứa Canxi, Photpho, Magie, VitaminD giúp duy trì cấu trúc xương chắc khỏe cho Mẹ và hình thành hệ xương khỏe mạnh cho bé",
        },
      },
    ],
  },
  {
    topicId: 2,
    normalQuestions: [
      {
        questionId: 101,
        thumbnail: "/assets/images/B1.jpg",
        thumbnailType: "img",
        description:
          "Những thực phẩm Mẹ cần chú trọng để kiểm soát cân nặng tốt hơn trong suốt thai kỳ?",
        answers: {
          A: "ColosBaby Gold for Mum",
          B: "Ngũ cốc",
          C: "Trái cây và rau sạch",
          D: "Tất cả đáp án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả đáp án trên",
          description:
            "Ngoài các chất dinh dưỡng, vitamin và khoáng chất thiết yếu có trong các thực phẩm hàng ngày, Mẹ cần chú trọng uống ColosBaby for Mum để kiểm soát tỷ lệ tăng cân hợp lý .ColosBaby for Mum giúp Kiểm Soát Cân nặng cho Mẹ nhờ cung cấp năng lượng hợp lý, giàu đạm, ít béo, tỷ lệ vừa đủ năng lượng và protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp Mẹ tăng cân hợp lý và kiểm soát cân nặng trong thai kỳ và sau sinh. Kiểm soát tốt cân nặng trong thời kỳ mang thai đồng thời giúp bé có số cân nặng ổn định khi chào đời",
        },
      },
      {
        questionId: 102,
        thumbnail: "/assets/images/B2.jpg",
        thumbnailType: "img",
        description:
          "Những thực phẩm nào sau đây tốt cho tiêu hóa của Mẹ trong suốt thời kỳ mang thai?",
        answers: {
          A: "ColosBaby Gold for Mum",
          B: "Thực phẩm nhiều dầu, mỡ",
          C: "Thức ăn nhanh, đóng hộp",
          D: "Nước ngọt, rượu, bia",
        },
        correctAnswer: {
          answer: "A",
          content: "ColosBaby Gold for Mum",
          description:
            "Việc tiết sữa Mẹ khi mang thai là hoàn toàn bình thường và không có gì đáng lo ngại. Đó chỉ là một dấu hiệu cho thấy cơ thể bạn đang chuẩn bị cho sự ra đời của em bé. Mẹ bầu nên bình tĩnh theo dõi lượng sữa non tiết ra, nếu quá nhiều hoặc có dấu hiệu bất thường thì cần đi khám sản khoa ngay",
        },
      },
      {
        questionId: 103,
        thumbnail: "/assets/images/B3.jpg",
        thumbnailType: "img",
        description:
          "Mẹ bầu uống ColosBaby Gold for Mum sẽ mang lại lợi ích gì cho thai nhi?",
        answers: {
          A: "Phát triển trí não",
          B: "Giúp hệ xương chắc khỏe",
          C: "Ngừa dị tật thai nhi",
          D: "Tất cả đáp án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả đáp án trên",
          description: "",
        },
      },
      {
        questionId: 104,
        thumbnail: "/assets/images/Bu-hinh-trong.jpg",
        thumbnailType: "img",
        description:
          "Những nhóm thực phẩm dinh dưỡng bổ sung nào nên có trong khẩu phần ăn của Mẹ bầu?",
        answers: {
          A: "Chất đạm, béo, bột, đường",
          B: "Vitamin và khoáng chất",
          C: "ColosBaby Gold for Mum",
          D: "Tất cả các đáp án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả các đáp án trên",
          description: "",
        },
      },
      {
        questionId: 105,
        thumbnail: "/assets/images/B5.jpg",
        thumbnailType: "img",
        description:
          "Mẹ bầu thường rất ngại dùng thuốc khi bị ốm trong thời kỳ mang thai, giải pháp cho Mẹ là gì?",
        answers: {
          A: "Tự dùng kháng sinh",
          B: "ColosBaby Gold For Mum",
          C: "Theo tư vấn bác sĩ",
          D: "Cả B và C",
        },
        correctAnswer: {
          answer: "D",
          content: "Cả B và C",
          description:
            "ColosBaby for Mum giúp hệ miễn dịch khỏe vì có chứa lượng kháng thể cao IgG 700mg từ sữa non, kết hợp các yếu tố chống oxy hóa như Vitamin A, C, E, Kẽm, Selen giúp tăng khả năng loại bỏ các vi khuẩn, Virus… bảo vệ cơ thể Mẹ trước những yếu tố gây bệnh từ môi trường, cho Mẹ thai kỳ khỏe mạnh, không ốm vặt.",
        },
      },
    ],
    hardQuestions: [
      {
        questionId: 106,
        thumbnail: "/assets/images/B6.jpg",
        thumbnailType: "img",
        description:
          "Mẹ bầu nên hoàn thiện khẩu phần dinh dưỡng của mình mỗi ngày bằng cách nào để đảm bảo dinh dưỡng cho thai nhi?",
        answers: {
          A: "Bổ sung 2-3 ly sữa bầu",
          B: "Ăn nhiều đồ ngọt",
          C: "Chỉ ăn trái cây có màu đỏ",
          D: "Chỉ ăn cơm",
        },
        correctAnswer: {
          answer: "A",
          content: "Bổ sung 2-3 ly sữa bầu",
          description: "",
        },
      },
    ],
  },
  {
    topicId: 3,
    normalQuestions: [
      {
        questionId: 201,
        thumbnail: "/assets/images/C1.jpg",
        thumbnailType: "img",
        description:
          "Khi Mẹ kiểm soát tốt cân nặng thì tăng cân thế nào là hợp lý trong suốt thai kỳ?",
        answers: {
          A: "Tăng 1 kg",
          B: "Tăng 5-6 kg",
          C: "Tăng 4-5 kg",
          D: "Tăng 10 -12 kg",
        },
        correctAnswer: {
          answer: "D",
          content: "Tăng 10 -12 kg",
          description:
            "Nếu người Mẹ có cân nặng bình thường trước khi mang thai (BMI khoảng 18,5 – 24,9): mức tăng cân lý tưởng của người Mẹ là 10 – 12 kg (tức khoảng 20% cân nặng trước khi mang thai.Trường hợp người Mẹ mang song thai: nên tăng khoảng 16-20,5 kg. ColosBaby for Mum giúp Kiểm Soát Cân nặng cho Mẹ nhờ cung cấp năng lượng hợp lý, giàu đạm, ít béo, tỷ lệ vừa đủ năng lượng và protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp Mẹ tăng cân hợp lý và kiểm soát cân nặng trong thai kỳ và sau sinh",
        },
      },
      {
        questionId: 202,
        thumbnail: "/assets/images/Bu-hinh-trong.jpg",
        thumbnailType: "img",
        description: "Độ tuổi mang thai lý tưởng của Mẹ là bao nhiêu?",
        answers: {
          A: "15-25 tuổi",
          B: "30-40 tuổi",
          C: "20-30 tuổi",
          D: "35-45 tuổi",
        },
        correctAnswer: {
          answer: "C",
          content: "20-30 tuổi",
          description:
            "Về mặt sinh học, độ tuổi tốt nhất để phụ nữ mang thai là ở độ tuổi đôi mươi. Đến lúc đó, cơ thể của người phụ nữ đã đủ trưởng thành để thực sự mang thai và đã có một nền tảng vững chắc trước khi khả năng sinh sản bắt đầu giảm (bắt đầu ở tuổi 32 và giảm nhanh hơn sau 37 tuổi, tính theo ACOG).",
        },
      },
      {
        questionId: 203,
        thumbnail: "/assets/images/Bu-hinh-trong.jpg",
        thumbnailType: "img",
        description: "Cân nặng lý tưởng của trẻ sơ sinh là bao nhiêu?",
        answers: {
          A: "2.500g",
          B: "3.000g",
          C: "4.000g",
          D: "3.200g-3.500g",
        },
        correctAnswer: {
          answer: "D",
          content: "3.200g-3.500g",
          description:
            "ColosBaby for Mum giúp Kiểm Soát Cân nặng cho Mẹ nhờ cung cấp năng lượng hợp lý, giàu đạm, ít béo, tỷ lệ vừa đủ năng lượng và protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp Mẹ tăng cân hợp lý và kiểm soát cân nặng trong thai kỳ và sau sinh. Kiểm soát tốt cân nặng trong thời kỳ mang thai đồng thời giúp bé có số cân nặng ổn định khi chào đời",
        },
      },
      {
        questionId: 204,
        thumbnail: "/assets/images/C4.jpg",
        thumbnailType: "img",
        description:
          "Trong 6 tháng cuối thai kỳ, giải pháp nào tốt nhất để mẹ bầu tăng cường hệ miễn dịch và kiểm soát cân nặng?",
        answers: {
          A: "Ăn uống đủ chất",
          B: "Dùng ColosBaby for Mum",
          C: "Bổ sung sữa non ColosIgG24h",
          D: "Tất cả các phương án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả các phương án trên",
          description:
            "ColosBaby for Mum giúp Kiểm Soát Cân nặng cho Mẹ nhờ cung cấp năng lượng hợp lý, giàu đạm, ít béo, tỷ lệ vừa đủ năng lượng và protein theo đúng nhu cầu tăng thêm trong thai kỳ, giúp Mẹ tăng cân hợp lý và kiểm soát cân nặng trong thai kỳ và sau sinh. Kiểm soát tốt cân nặng trong thời kỳ mang thai đồng thời giúp bé có số cân nặng ổn định khi chào đời",
        },
      },
    ],
    hardQuestions: [
      {
        questionId: 205,
        thumbnail: "/assets/images/C5.jpg",
        thumbnailType: "img",
        description:
          "Tại sao sau sinh Mẹ vẫn cần duy trì bổ sung ColosBaby Gold for Mum mỗi ngày?",
        answers: {
          A: "Phục hồi sơ thể sau sinh",
          B: "Kiểm soát cân nặng",
          C: "Tăng cường miễn dịch",
          D: "Tất cả đáp án trên",
        },
        correctAnswer: {
          answer: "D",
          content: "Tất cả các đáp án trên",
          description: "",
        },
      },
    ],
  },
];
