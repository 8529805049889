import { Settings } from "react-slick";
import { IHistory } from "./interfaces";

export function getHistoryMessage(data: IHistory[]) {
  // return data.map(({ userName, giftName }) => giftName + " " + userName);
  return data.map(({ userName, giftName, giftId }) => {
    if (giftId === -1) {
      return `${userName} đã tham gia chơi.`;
    } else {
      return `${userName} đã trúng ${giftName}`;
    }
  });
}

export const HISTORY_CAROUSEL_SETTINGS: Settings = {
  className: "history",
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
};
