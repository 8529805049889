import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useCheckboxGroup,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ButtonModalI, SurveyI } from "../../../../interfaces/app.interface";
import {
  updateCurrentScreen,
  updateIsOpenPopUp,
  updateIsOpenSurvey,
} from "../../../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { postSurveyService } from "../service";
import SurveyCard from "./SurveyCard";

type SurveyProps = {
  survey: SurveyI | null;
  onClose: () => void;
  isOpen: boolean;
  onNotHaveTurn?: () => void;
};

const buttonBack: ButtonModalI = {
  title: "QUAY LẠI",
  screen: "question",
};

const buttonComplete: ButtonModalI = {
  title: "HOÀN THÀNH",
};
const buttonToHome: ButtonModalI = {
  title: "TRANG CHỦ",
  screen: "home",
};

function ModalSurvey({ isOpen, onClose, survey, onNotHaveTurn }: SurveyProps) {
  const dispatch = useAppDispatch();
  const turn = useAppSelector((state) => state.appReducer.turn);
  const [disabled, setDisabled] = useState(false);

  const { value, getCheckboxProps } = useCheckboxGroup({});
  let isChoosed = value.length > 0;
  async function handleRedirect(button: ButtonModalI) {
    if (button.title === "HOÀN THÀNH") {
      const userAnswers = value.map((value) => {
        return { answer: value as string };
      });

      try {
        if (!disabled) {
          setDisabled(true);
          console.log("da");
          await postSurveyService({
            surveyId: 1,
            questions: [
              {
                question: survey?.questions[0].question as string,
                answers: userAnswers,
              },
            ],
          });
        }
      } catch (e) {
        console.log(e);
      }
      if (turn === 0) {
        dispatch(updateIsOpenPopUp(true));
        onNotHaveTurn && onNotHaveTurn();
      } else {
        dispatch(updateCurrentScreen("home"));
      }
    } else {
      dispatch(updateIsOpenSurvey(false));
      dispatch(updateCurrentScreen(button.screen));
    }
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent w="280px" p="24px 24px 0px 24px" borderRadius="15px">
        <VStack w="100%" align="center">
          <Text
            fontWeight="600"
            fontStyle="normal"
            fontSize="14px"
            lineHeight="19.5px"
            textAlign="center"
          >
            {survey?.questions[0].question}
          </Text>
          {!isChoosed && (
            <Text
              fontSize="10px"
              fontWeight="500"
              color="#444444"
              pt="4px"
              w="100%"
            >
              Bạn có thể chọn nhiều câu trả lời
            </Text>
          )}
          {JSON.parse(survey?.questions[0].answerOption as string).map(
            (answer: string, index: number) => {
              const label = `${String.fromCharCode(65 + index)}. ${answer}`;
              const checkbox = getCheckboxProps({ value: answer });
              return (
                <SurveyCard key={index} checkboxProps={checkbox}>
                  {label}
                </SurveyCard>
              );
            }
          )}
          {isChoosed && (
            <Text
              fontSize="10px"
              fontWeight="500"
              color="#444444"
              pb="0px"
              w="100%"
            >
              Cảm ơn câu trả lời của bạn.
            </Text>
          )}
          {isChoosed && (
            <Box
              w="184px"
              h="40px"
              borderRadius="20px"
              bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)"
              fontSize="14px"
              fontWeight="700"
              color="#004E29"
              mt="16px"
              boxShadow="-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)"
              textTransform="uppercase"
              onClick={() => handleRedirect(buttonComplete)}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              HOÀN THÀNH
            </Box>
          )}
          <Box
            w="184px"
            // h="40px"
            borderRadius="20px"
            fontSize="14px"
            fontWeight="700"
            // mt="30px"
            pb="20px"
            pt={isChoosed ? "0px" : "14px"}
            textTransform="uppercase"
            onClick={() =>
              handleRedirect(isChoosed ? buttonToHome : buttonBack)
            }
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#004E29"
          >
            {isChoosed ? "TRANG CHỦ" : "QUAY LẠI"}
          </Box>
        </VStack>
      </ModalContent>
    </Modal>
  );
}

export { ModalSurvey };
