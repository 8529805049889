import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PopUp from "../common/pop_up";
import { DATA_POPUP_ERROR } from "../common/pop_up/constants";
import { getPhoneNumber } from "./service";

export default function ShareFriends() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const [togglePopUp, setTogglePopUp] = useState(false);
  function handleClosePopUp() {
    setTogglePopUp(false);
  }
  const handleCopyUrl = () => {
    // const el = document.createElement("input");
    // el.value = "http://onelink.to/vitadairy";
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand("copy");
    // document.body.removeChild(el);
    navigator.clipboard.writeText("http://onelink.to/vitadairy");
  };
  const handleCopyPhoneNumber = () => {
    // const el = document.createElement("input");
    // el.value = phoneNumber;
    // document.body.appendChild(el);
    // el.select();
    // document.execCommand("copy");
    // document.body.removeChild(el);
    navigator.clipboard.writeText(phoneNumber);
  };
  useEffect(() => {
    (async () => {
      try {
        const phone = await getPhoneNumber();
        setPhoneNumber(phone);
      } catch (e) {
        console.log(e);
        setTogglePopUp(true);
      }
    })();
  }, []);
  return (
    <Box position="relative" w="100vw">
      <Box px={"16px"} position="absolute" top="110px" w="100%">
        <Box fontWeight="600" fontSize="14px" color="#7FAA12" mb="8px">
          Chia sẻ bạn bè để tải ứng dụng
        </Box>
        <Flex
          w="100%"
          bg="#F3F8E1"
          borderRadius="8px"
          p="8px"
          justifyContent="space-between"
        >
          <Box w="calc(100% - 140px)">
            <Text fontSize="12px" fontWeight="500" mb="8px" color="#444444">
              Link tải ứng dụng
            </Text>
            <Flex h="32px" w="100%">
              <Box
                border="1px solid #7FAA12"
                borderLeftRadius="6px"
                lineHeight={"32px"}
                fontSize="12px"
                fontWeight="400"
                pl="4px"
                w="calc(100% - 32px)"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                bg="white"
              >
                http://vitadairy.vn:...
              </Box>
              <Flex
                w="32px"
                h="32px"
                bg="#7FAA12"
                justifyContent="center"
                alignItems="center"
                borderRightRadius="6px"
                onClick={() => {
                  handleCopyUrl();
                }}
              >
                <Image
                  src="/assets/images/Copy.svg"
                  alt="copy"
                  w="16px"
                  objectFit="contain"
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <Text
              fontSize="12px"
              fontWeight="500"
              textAlign="center"
              color="#444444"
            >
              Quét mã QR code
            </Text>
            <Image src="/assets/images/QRCode.png" alt="qr" h="140px" />
          </Box>
        </Flex>
        <Box
          fontSize="14px"
          fontWeight="600"
          color="#7FAA12"
          mt="16px"
          mb="8px"
        >
          Giới thiệu bạn bè để nhận quà
        </Box>
        <Box bg="#F3F8E1" p="16px 8px" borderRadius="8px">
          <Box mb="8px" fontSize="12px" fontWeight="500" color="#444444">
            Mã giới thiệu của bạn
          </Box>
          <Flex justifyContent="space-between">
            <Box fontSize="14px" fontWeight="600" color="#000000">
              {phoneNumber}
            </Box>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              onClick={() => {
                handleCopyPhoneNumber();
              }}
            >
              <Box mr="8px" fontSize="12px" fontWeight="600" color="#7FAA12">
                SAO CHÉP
              </Box>
              <Image
                src="/assets/images/Copy-green.svg"
                alt="copy"
                w="15px"
                objectFit="contain"
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <PopUp
        {...DATA_POPUP_ERROR}
        isOpen={togglePopUp}
        onClose={handleClosePopUp}
      />
    </Box>
  );
}
