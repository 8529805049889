import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import {
  updateCurrentScreen,
  updateIsOpenDrawer,
} from "../../../redux/app.reducers";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { PREFIX_MOBILE_URL } from "../nav/contants";

type Props = {};

const DrawerShare = (props: Props) => {
  const dispatch = useAppDispatch();
  // const isOpenDrawer = useSelector(
  //   (state: RootState) => state.appReducer.isOpenDrawer
  // );
  const isOpenDrawer = useAppSelector((state) => state.appReducer.isOpenDrawer);
  const onClose = () => {
    dispatch(updateIsOpenDrawer(false));
  };

  const handleSwitchScanQR = () => {
    window.location.replace(PREFIX_MOBILE_URL + "/scan-qr");
  };

  return (
    <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpenDrawer}>
      <DrawerOverlay />
      <DrawerContent
        borderTopRadius={"16px"}
        bg="conic-gradient(from 0deg at 100% 100%, #87C033 -0.81deg, #3B9453 244.7deg, #A0C31E 284.31deg, #87C033 359.19deg, #3B9453 604.7deg);"
      >
        <DrawerHeader p={"16px 16px 20px 16px"}>
          <Flex flexDirection="row-reverse">
            <Box
              onClick={onClose}
              w="24px"
              h="24px"
              bg="#FBF8F3"
              borderRadius={"50%"}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image src={"/assets/images/Close.svg"} />
            </Box>
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0} m={0}>
          <Box p={"0px 16px 24px 16px"}>
            <Box
              p="16px"
              bg="#F3F8E1"
              border={"1px solid #E9E9E9"}
              borderRadius="8px"
            >
              <Flex>
                <Image src={"/assets/images/Coin.svg"} w="24px" />
                <Text
                  ml="8px"
                  fontSize="16px"
                  fontWeight="700"
                  textTransform="uppercase"
                >
                  ĐỔI MUỖNG TÍCH XU
                </Text>
              </Flex>
              <Text mt="6px" fontSize="14px" fontWeight="500">
                Bạn hãy tích thêm xu để nhận thêm lượt nhé!
              </Text>
              <Flex flexDirection="row-reverse" mt="6px">
                <Box
                  h="40px"
                  w="184px"
                  textTransform="uppercase"
                  fontSize="14px"
                  borderRadius="20px"
                  bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)"
                  boxShadow={
                    "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)"
                  }
                  color="#004E29"
                  textAlign="center"
                  lineHeight="40px"
                  fontWeight="700"
                  onClick={() => {
                    handleSwitchScanQR();
                  }}
                >
                  Quét mã ngay
                </Box>
              </Flex>
            </Box>

            {/* <Box
              p="16px"
              bg="#F3F8E1"
              border={"1px solid #E9E9E9"}
              mt="16px"
              borderRadius="8px"
            >
              <Flex>
                <Image src={"/assets/images/UserFriends.svg"} w="24px" />
                <Text
                  ml="8px"
                  fontSize="16px"
                  fontWeight="700"
                  textTransform="uppercase"
                >
                  Chia sẻ bạn bè
                </Text>
              </Flex>
              <Text mt="6px" fontSize="14px" fontWeight="500">
                Bạn hãy{" "}
                <span style={{ fontWeight: "bold" }}>chia sẻ bạn bè</span> để có
                thêm lượt nhé (Không giới hạn số lượng)
              </Text>
              <Flex flexDirection="row-reverse" mt="6px">
                <Box
                  h="40px"
                  w="184px"
                  textTransform="uppercase"
                  fontSize="14px"
                  borderRadius="20px"
                  bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)"
                  boxShadow={
                    "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)"
                  }
                  color="#004E29"
                  textAlign="center"
                  lineHeight="40px"
                  fontWeight="700"
                  onClick={() => {
                    dispatch(updateIsOpenDrawer(false));
                    dispatch(updateCurrentScreen("share-friends"));
                  }}
                >
                  Chia sẻ ngay
                </Box>
              </Flex>
            </Box>
            <Box
              p="16px"
              bg="#F3F8E1"
              border={"1px solid #E9E9E9"}
              mt="16px"
              mb="14px"
              borderRadius="8px"
            >
              <Flex>
                <Image src={"/assets/images/Facebook.svg"} w="24px" />
                <Text
                  ml="8px"
                  fontSize="16px"
                  fontWeight="700"
                  textTransform="uppercase"
                >
                  Chia sẻ facebook
                </Text>
              </Flex>
              <Text mt="6px" fontSize="14px" fontWeight="500">
                Bạn hãy{" "}
                <span style={{ fontWeight: "bold" }}>chia sẻ Facebook </span>để
                nhận lượt chơi (Tối đa 1 lượt)
              </Text>
              <Flex flexDirection="row-reverse" mt="6px">
                <Box
                  h="40px"
                  w="184px"
                  textTransform="uppercase"
                  fontSize="14px"
                  borderRadius="20px"
                  bg="linear-gradient(90deg, #B79003 -17.93%, #EADE00 22.34%, #E7E600 67.08%, #C1A200 116.3%)"
                  boxShadow={
                    "-2px 2px 4px rgba(0, 0, 0, 0.25), inset -2px 2px 4px #FEF693, inset 2px -2px 4px rgba(0, 0, 0, 0.34)"
                  }
                  color="#004E29"
                  textAlign="center"
                  lineHeight="40px"
                  fontWeight="700"
                >
                  Chia sẻ ngay
                </Box>
              </Flex>
            </Box> */}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerShare;
