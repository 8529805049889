import { execute } from "../../libs/request";

export const getPhoneNumber = async () => {
  const phoneNumber = (
    await execute.get("https://api-stg.vitadairyvietnam.vn/api/user/detail")
  ).data.response.phoneNumber;
  if (phoneNumber) {
    return phoneNumber;
  } else {
    throw new Error("Error getInfoUser");
  }
};
