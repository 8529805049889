import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import DrawerShare from "./components/common/drawer";
import Nav from "./components/common/nav";
import Dashboard from "./components/dashboard";
import Home from "./components/home";
import Policy from "./components/policy";
import Question from "./components/question/Question";
import Rank from "./components/rank";
import ShareFriends from "./components/share-friends";
import Success from "./components/success";
import { updatePlayAudio } from "./redux/app.reducers";
import { useAppDispatch, useAppSelector } from "./redux/store";

type Props = {
  currentScreen:
    | "home"
    | "dashboard"
    | "question"
    | "success"
    | "policy"
    | "rank"
    | "share-friends";
  handlePlayPauseAudio: () => void;
};
const RenderScreen = (props: Props) => {
  const { handlePlayPauseAudio, currentScreen } = props;
  switch (currentScreen) {
    case "home":
      return <Home handlePlayPauseAudio={handlePlayPauseAudio} />;
    case "question":
      return <Question />;
    case "success":
      return <Success />;
    case "dashboard":
      return <Dashboard />;
    case "policy":
      return <Policy />;
    case "rank":
      return <Rank />;
    case "share-friends":
      return <ShareFriends />;
    default:
      return <Box>404</Box>;
  }
};

let audio: HTMLAudioElement | null = null;

function App() {
  const dispatch = useAppDispatch();
  const currentScreen = useAppSelector(
    (state) => state.appReducer.currentScreen
  );

  const playAudio = useAppSelector((state) => state.appReducer.playAudio);
  useEffect(() => {
    audio = new Audio("/assets/audio/background.mp3");
    audio.preload = "metadata";
    audio.currentTime = 2;
    audio.loop = true;
    return () => {
      audio?.pause();
    };
  }, []);
  function handlePlayPauseAudio() {
    if (!playAudio) {
      dispatch(updatePlayAudio(true));
      audio?.play();
    } else {
      dispatch(updatePlayAudio(false));
      audio?.pause();
    }
  }

  return (
    <>
      <Nav handlePlayPauseAudio={handlePlayPauseAudio} />
      <RenderScreen
        handlePlayPauseAudio={handlePlayPauseAudio}
        currentScreen={currentScreen}
      />
      <DrawerShare />
    </>
  );
}

export default App;
